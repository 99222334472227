import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PLLogo } from '../assets/icons/icon';
import { ButtonFilled } from '../Components/Buttons';

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-[100vh] FLEX_CENTER flex-col gap-5 max_md:m-auto max_md:px-10 max_sm:px-5">
      <div className="w-[210px] max_md:w-[170px]">
        <PLLogo color="#333333" />
      </div>
      <p className="mt-[60px] max_md:mt-10 text-[100px] max_sm:text-[70px] max_sm:leading-[54px] text-ButtonPrimary font-extrabold font-OutfitFont leading-[100px]">
        404
      </p>
      <p className="mt-[30px] text-[40px] font-OutfitFont font-semibold max_md:mt-5 text-PrimaryT leading-[50px] max_sm:text-[32px]">
        Page not found
      </p>
      <p className="mt-4 max_md:mt-3 text-[26px] font-OutfitFont font-normal text-TernaryT text-center max_sm:text-[22px]">
        The page you're looking for doesn't exist or has been moved.
      </p>
      <div className="mt-[60px] max_md:mt-10">
        <ButtonFilled
          title="Back to homepage"
          rounded="12px"
          onClick={() => navigate('/')}
        />
      </div>
    </div>
  );
};

export default PageNotFound;
