import React, { useEffect, useRef, useState } from 'react';

import OurStory from '../Components/HomePage/OurStory';
import OurServices from '../Components/HomePage/OurServices';
import OurProducts from '../Components/HomePage/OurProducts';
import ContactUs from '../Components/HomePage/ContactUs';
import HeroSection from '../Components/HomePage/HeroSection';
import { useTranslation } from 'react-i18next';

const HomePage = () => {
  const { t } = useTranslation();
  const [menus] = useState([
    {
      name: t('Home'),
      link: 'ScrollTop',
    },
    {
      name: t('OurStory'),
      link: 'OurStoryId',
    },
    {
      name: t('WhyPalmLabs'),
      link: 'WhyPalmLabsId',
    },
    {
      name: t('DCDial'),
      link: 'DCDialId',
    },
    {
      name: t('Avnovo'),
      link: 'AvnovoId',
    },
    {
      name: t('Epifony'),
      link: 'EpifonyId',
    },
  ]);
  const [selected, setSelected] = useState(menus[0].name);
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = React.useState(null);

    React.useEffect(() => {
      let lastScrollY = window.pageYOffset;

      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        const direction = scrollY > lastScrollY ? 'down' : 'up';
        if (
          direction !== scrollDirection &&
          (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
        ) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener('scroll', updateScrollDirection); // add event listener
      return () => {
        window.removeEventListener('scroll', updateScrollDirection); // clean up
      };
    }, [scrollDirection]);

    return scrollDirection;
  }
  const scrollDirection = useScrollDirection();

  const sectionRefs = useRef({
    ourStory: React.createRef(),
    ourService: React.createRef(),
    Dcdial: React.createRef(),
    avnovo: React.createRef(),
    epifony: React.createRef(),
    home: React.createRef(),
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const foundObject = menus.find(
              (item) => item.link === entry.target.id
            );
            setSelected(foundObject.name);
          }
        });
      },
      { threshold: 0.6 }
    ); // Adjust threshold to when you consider the section "active"

    Object.values(sectionRefs.current).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      Object.values(sectionRefs.current).forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);

  return (
    <>
      <div className="max-w-[1920px] m-auto max_md:hidden">
        <div
          className={`fixed w-full max-w-[1920px] m-auto z-40 ${
            scrollDirection === 'down' ? 'bottom-[-80px]' : 'bottom-10'
          } transition-all duration-500`}
        >
          <div className="z-50 max-w-[620px] m-auto bg-PrimaryT rounded-[100px] p-[10px] border-StrokeActive border-[1px] flex">
            {menus.map((menu, index) => (
              <p
                className={`rounded-[100px] py-[15px] px-[18px] Text16Normal cursor-pointer ${
                  selected === menu.name
                    ? 'bg-BgTernary text-ButtonPrimary'
                    : ''
                }`}
                key={index}
                onClick={() => {
                  setSelected(menu.name);
                  handleClickScroll(menu.link);
                }}
              >
                {menu.name}
              </p>
            ))}
          </div>
        </div>
      </div>
      <HeroSection
        sectionRefs={sectionRefs.current.home}
        handleClickScroll={handleClickScroll}
      />
      <OurStory sectionRefs={sectionRefs.current.ourStory} />
      <OurServices sectionRefs={sectionRefs.current.ourService} />
      <OurProducts sectionRefs={sectionRefs} />
      <ContactUs />
    </>
  );
};

export default HomePage;
