import React, { useEffect } from 'react';
import CommonSection from '../Components/Common/CommonSection';
import { useTranslation } from 'react-i18next';
import CookiePolicyContent from '../Components/Privacy/CookiePolicyContent';

const CookiePolicy = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <CommonSection>
        <h2 className="Text50Bold text-PrimaryT max-w-[740px]">
          {t('Cookie')}
          <span className="text-ButtonPrimary"> {t('Policy')}</span>
        </h2>
        <p className="Text24 text-PrimaryT mt-5 max_sm:mt-3">
          Last Modified: June 25, 2024
        </p>
        <CookiePolicyContent />
      </CommonSection>
    </div>
  );
};

export default CookiePolicy;
