import React, { useState } from 'react';
import CommonSection from './Common/CommonSection';
import { LineIcon, PLLogo } from '../assets/icons/icon';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from './Hooks/useWindowSize';
import { useLocation, useNavigate } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  const navigate = useNavigate();
  const { width: windowWidth } = useWindowSize();
  const isSmallDesktop = windowWidth <= 1300;
  const [menus] = useState([
    {
      name: t('OurStory'),
      link: 'OurStoryId',
    },
    {
      name: t('WhyPalmLabs'),
      link: 'WhyPalmLabsId',
    },
    {
      name: t('DCDial'),
      link: 'DCDialId',
    },
    {
      name: t('Avnovo'),
      link: 'AvnovoId',
    },
    {
      name: t('Epifony'),
      link: 'EpifonyId',
    },
    {
      name: t('ContactUs'),
      link: 'ContactId',
    },
  ]);
  const handleClickScroll = (id) => {
    if (location.pathname !== '/') {
      navigate('/');
    }
    setTimeout(() => {
      const element = document.getElementById(id);

      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - 80;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }, 600);
  };
  const handlePrivacy = () => {
    navigate('/privacy-policy');
    setTimeout(() => {
      window.scrollTo(0,0)
    },400)
  }
  return (
    <div className="max-w-[1920px] m-auto bg-PrimaryT">
      <CommonSection>
        <div className="flex flex-col gap-10 max_md:gap-[50px] max_sm:gap-10">
          <div className="flex items-center justify-between max_md:flex-col max_md:items-start max_md:gap-10">
            <div>
              <PLLogo color="#FFFFFF" />
            </div>
            <div className="max_md:hidden">
              <LineIcon style={{ width: isSmallDesktop ? '180' : '302' }} />
            </div>
            <div className="flex gap-6 max_md:max-w-[430px] max_600:max-w-[335px] max_md:flex-wrap">
              {menus.map((menu, index) => (
                <p
                  className="Text16Normal text-white max_sm:text-textExtraSmall cursor-pointer"
                  key={index}
                  onClick={() => handleClickScroll(menu.link)}
                >
                  {menu.name}
                </p>
              ))}
              <p
                className="hidden Text16Normal text-white text-textExtraSmall max_600:block cursor-pointer"
                onClick={() => handlePrivacy()}
              >
                Privacy Policy
              </p>
            </div>
          </div>
          <div className="flex items-center gap-4 Text16Normal text-white max_800:gap-2 max_sm:text-textExtraSmall">
            <p>© {currentYear} Copyright Palm Labs | All Rights Reserved.</p>
            <p className="max_600:hidden">|</p>
            <p
              className="max_600:hidden cursor-pointer"
              onClick={() => handlePrivacy()}
            >
              Privacy Policy
            </p>
          </div>
        </div>
      </CommonSection>
    </div>
  );
};

export default Footer;
