import React, { useEffect, useState } from 'react';
import { InputField, InputTextArea } from '../InputFields';
import {
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
  validateCaptcha,
} from 'react-simple-captcha';
import { ButtonFilled } from '../Buttons';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../Hooks/useWindowSize';
import SelectMobileNumber from '../Common/SelectMobileNumber';
import axios from 'axios';
import { TickMarkGreenIcon } from '../../assets/icons/icon';
import SelectPhoneNew from '../Common/SelectPhoneNew';

const ContactForm = ({ userCountry }) => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const isTabView = windowWidth <= 980;
  const initialData = {
    firstName: '',
    lastName: '',
    email: '',
    mobile_no: '',
    message: '',
  };
  const [userDetails, setUserDetails] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [inputMobileData, setInputMobileData] = useState({
    country_code: '',
    phone_number: '',
  });
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    mobile_no: false,
    message: false,
  });
  const [captchaValue, setCaptchaValue] = useState('');
  const [captchaErr, setCaptchaErr] = useState(false);
  const [countryLib, setCountryLib] = useState(
    userCountry.country_code || 'US'
  );
  const handleOnChange = (e, name) => {
    if (name === 'mobile_no') {
      if (!/^\d*$/.test(e.target.value)) {
        return; // If the value is not a number, do nothing
      }
    }
    setUserDetails({
      ...userDetails,
      [name]: e.target.value, // Update the specific field based on fieldName
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const reloadCaptcha = () => {
    loadCaptchaEnginge(6, 'transparent', '#1d1d1d');
  };
  useEffect(() => {
    reloadCaptcha();
  }, []);

  useEffect(() => {
    let context = document.getElementById('canv');
    if (context) {
      // context.classList.add('my-custom-captcha');
      const parentDiv = document.createElement('div');

      // Add a class name to the parent div
      parentDiv.className = 'my-custom-captcha';

      // Insert the new parent div before the child div in the DOM
      context.parentNode.insertBefore(parentDiv, context);

      // Move the child div inside the new parent div
      parentDiv.appendChild(context);
    }
  }, []);
  const handleOnChangeCaptcha = (e) => {
    setCaptchaValue(e.target.value);
  };

  const validateForm = () => {
    const newErrors = {};

    // Check for non-empty fields
    newErrors.firstName = !userDetails.firstName;
    newErrors.lastName = !userDetails.lastName;
    newErrors.email = !userDetails.email;
    newErrors.message = !userDetails.message;

    if (!newErrors.email) {
      newErrors.email = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userDetails.email);
    }

    return newErrors;
  };
  const toStoreData = async () => {
    const storeData = {
      type: 'add_account',
      api_key: '71a629e3685846a9173884a03f8f559a4d3c6510',
      account: {
        first_name: userDetails.firstName,
        last_name: userDetails.lastName,
        company_id: '10696',
        account_status: 'interested',
      },
      phones: [
        {
          phone_number: inputMobileData.phone_number,
          phone_type: 'MOBILE',
        },
      ],
      emails: [userDetails.email],
      accounts_leads: {
        list_id: '9923013041216',
      },
    };

    axios
      .post('https://api.dcdial.com/api/', storeData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        // console.log('111', response);
      })
      .catch((err) => {
        // console.log('22222222222');
      });
  };
  const sendEmail = async () => {
    const data = {
      type: 'send_general_email',
      email_type: 'thankyou_contacting_palmlabs',
      email: userDetails.email,
      api_key: '71a629e3685846a9173884a03f8f559a4d3c6510',
      Name: userDetails.firstName + ' ' + userDetails.lastName,
      email_content: {
        message: userDetails.message,
        phone_number: inputMobileData.phone_number,
        inquiry: ''
      },
    };

    axios
      .post('https://api.dcdial.com/api/', data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          toStoreData();
          setIsSuccess(true);
          setUserDetails(initialData);
          setInputMobileData({
            country_code: '',
            phone_number: '',
          });
          setCaptchaValue('');
          reloadCaptcha();
          setCountryLib(userCountry.country_code || 'US');
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleSubmit = () => {
    setIsSuccess(false);
    const formErrors = validateForm();
    setErrors(formErrors);

    const hasErrors = Object.values(formErrors).some((error) => error);
    if (!hasErrors) {
      if (validateCaptcha(captchaValue) === true) {
        setCaptchaErr(false);
        sendEmail();
        setIsLoading(true);
      } else {
        setCaptchaErr(true);
      }
    }
  };
  return (
    <div className="max-w-[500px] max_xl:max-w-[470px] max_md:max-w-full flex flex-col gap-6 max_sm:gap-5">
      <div className="flex gap-5 w-full justify-between max_600:flex-col">
        <div className="w-full">
          <InputField
            type="text"
            placeholder="Your first name"
            handleOnChange={handleOnChange}
            name="firstName"
            value={userDetails.firstName}
            label="First Name"
            isRequired
            error={errors.firstName}
          />
        </div>
        <div className="w-full">
          <InputField
            type="text"
            placeholder="Your last name"
            handleOnChange={handleOnChange}
            name="lastName"
            value={userDetails.lastName}
            label="Last Name"
            error={errors.lastName}
          />
        </div>
      </div>
      <div>
        <InputField
          type="text"
          placeholder="youremail@gmail.com"
          handleOnChange={handleOnChange}
          name="email"
          value={userDetails.email}
          label="Email"
          isRequired
          error={errors.email}
        />
      </div>
      <div>
        {/* <SelectMobileNumber
          inputMobileData={inputMobileData}
          setInputValue={setInputMobileData}
          error={errors.mobile_no}
          setErrors={setErrors}
          defaultCountry={userCountry.country_code || 'US'}
          countryLib={countryLib}
          setCountryLib={setCountryLib}
        /> */}
        <p className="text-textSmall max_sm:text-textExtraSmall font-OutfitFont font-normal text-PrimaryT mb-2">
          Phone Number:
        </p>
        <SelectPhoneNew
          inputMobileData={inputMobileData}
          setInputValue={setInputMobileData}
          // errorPhone={error}
          setErrors={setErrors}
          detectedCountry={userCountry.country_code && userCountry.country_code}
          // handleOnBlur={handleBlurURL}
        />
      </div>
      <div>
        <InputTextArea
          type="text"
          placeholder="Type your message here..."
          handleOnChange={handleOnChange}
          name="message"
          value={userDetails.message}
          label="Message"
          isRequired
          error={errors.message}
        />
      </div>
      <div className="relative pt-12px">
        <InputField
          id="captcha"
          name="captcha"
          placeholder="7bGh62"
          value={captchaValue}
          handleOnChange={handleOnChangeCaptcha}
          error={captchaErr}
          isRequired
          label="Enter Captcha"
        />
      </div>
      <div className="flex w-full justify-center relative">
        <LoadCanvasTemplateNoReload reloadColor="red" className="w-[100%]" />
        <div
          className="absolute top-1 right-0 cursor-pointer"
          onClick={reloadCaptcha}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M22 12.5C22 18.02 17.52 22.5 12 22.5C6.48 22.5 3.11 16.94 3.11 16.94M3.11 16.94H7.63M3.11 16.94V21.94M2 12.5C2 6.98 6.44 2.5 12 2.5C18.67 2.5 22 8.06 22 8.06M22 8.06V3.06M22 8.06H17.56"
              stroke="#484540"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="w-full">
        <div className="FLEX_CENTER">
          <ButtonFilled
            title={t('SendMessage')}
            fontSize="16px"
            rounded="12px"
            height="46px"
            width={isTabView ? '168px' : '100%'}
            onClick={() => {
              if (!isLoading) {
                handleSubmit();
              }
            }}
            isLoading={isLoading}
          />
        </div>
        {isSuccess && (
          <div className="mt-4 flex gap-2">
            <div className="mt-1">
              <TickMarkGreenIcon style={{ width: 20, height: 20 }} />
            </div>
            <p className="Text16Normal text-[#23B339] max_sm:text-[14px]">
              Thank you for contacting Palm Labs! Your request has been
              submitted. We’ll get back to you soon.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
