export const InnovationIcon = (props) => {
  return (
    <>
      <svg
        {...props}
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.79846 23.8212C6.58916 23.8212 9.3069 23.8212 12.0702 23.8212C12.1979 26.3292 13.1464 28.4268 15.1163 29.9863C16.612 31.1719 18.3265 31.7008 20.2326 31.6735C23.7255 31.6279 27.6835 28.8737 27.948 23.8303C30.684 23.8303 33.4109 23.8303 36.1833 23.8303C36.1833 24.6147 36.2198 25.3807 36.1651 26.1468C36.1377 26.6119 35.7729 26.8764 35.3169 26.9858C34.6512 27.15 33.9854 27.2868 33.3288 27.4692C33.1646 27.5148 32.9822 27.6972 32.9093 27.8614C32.581 28.5909 32.2709 29.3388 31.9882 30.0866C31.9334 30.2234 31.9426 30.4423 32.0155 30.57C32.3256 31.1263 32.6721 31.6552 33.0096 32.2024C33.42 32.8773 33.3835 33.3242 32.8363 33.8714C31.9152 34.7925 30.9941 35.7227 30.073 36.6438C29.544 37.1728 29.1063 37.2184 28.4496 36.8171C27.9298 36.4979 27.41 36.1878 26.9084 35.8595C26.6712 35.7045 26.4797 35.6863 26.2061 35.8139C25.5039 36.1422 24.7743 36.3976 24.0812 36.7168C23.917 36.7898 23.7529 36.9722 23.7073 37.1363C23.5157 37.793 23.3881 38.4587 23.2057 39.1153C23.0324 39.7446 22.695 39.9909 22.0566 39.9909C20.6886 39.9909 19.3297 40 17.9617 39.9909C17.3416 39.9909 16.995 39.7446 16.8308 39.1427C16.6484 38.4861 16.4934 37.8203 16.3475 37.1546C16.2928 36.9174 16.2016 36.7806 15.9371 36.6803C15.2166 36.4158 14.4961 36.1149 13.803 35.7957C13.5477 35.6771 13.3653 35.6863 13.1373 35.8322C12.5901 36.1878 12.0338 36.5253 11.4774 36.8627C10.9302 37.191 10.456 37.1363 10 36.6803C9.05154 35.741 8.11219 34.7925 7.17283 33.844C6.64388 33.3151 6.59828 32.8591 6.99044 32.2116C7.31875 31.6826 7.62883 31.1445 7.97539 30.6247C8.12131 30.4058 8.13043 30.2417 8.02099 30.0045C7.70179 29.3023 7.43731 28.5727 7.11812 27.8705C7.03604 27.6972 6.85364 27.5057 6.67124 27.451C6.03284 27.2594 5.37621 27.1318 4.72869 26.9767C4.11766 26.8217 3.82582 26.4751 3.82582 25.8459C3.79846 25.1983 3.79846 24.5326 3.79846 23.8212Z"
          fill="#F84D2F"
        />
        <path
          d="M23.6434 23.2376C21.2084 23.2376 18.7825 23.2376 16.3566 23.2376C16.2654 22.736 16.2107 22.2344 16.0921 21.7601C15.8276 20.6749 15.244 19.7537 14.4596 18.9694C10.4833 14.9749 11.4683 8.63655 16.466 6.03737C20.7615 3.81211 26.3429 6.21065 27.6471 10.8709C28.5226 13.9899 27.7747 16.7077 25.4947 19.0059C24.4186 20.0821 23.8167 21.3588 23.7255 22.8819C23.7255 22.9548 23.7164 23.0369 23.7072 23.1099C23.689 23.1464 23.6708 23.1646 23.6434 23.2376ZM15.0433 11.6552C15.1436 11.6279 15.3716 11.6005 15.5814 11.5184C16.7761 11.026 17.6516 10.1778 18.2535 9.04694C18.363 8.83719 18.4542 8.60007 18.5089 8.37207C18.7004 7.52392 18.1988 7.02232 17.3598 7.26856C16.9767 7.378 16.5937 7.5604 16.2563 7.78839C15.2348 8.49063 14.414 9.36614 14.1313 10.6156C13.9945 11.2266 14.3228 11.6461 15.0433 11.6552Z"
          fill="#F84D2F"
        />
        <path
          d="M19.9955 27.5878C21.0078 27.5878 22.0292 27.5878 23.0415 27.5878C23.6434 27.5878 23.8258 27.8522 23.6343 28.4268C23.461 28.9558 22.9959 29.357 22.4122 29.357C20.8071 29.3753 19.202 29.3753 17.5878 29.357C16.9403 29.3479 16.4387 28.8828 16.311 28.2626C16.2198 27.8066 16.3749 27.5878 16.84 27.5878C17.907 27.5786 18.9467 27.5878 19.9955 27.5878Z"
          fill="#F84D2F"
        />
        <path
          d="M20.0137 25.7273C21.0625 25.7273 22.1021 25.7273 23.1509 25.7273C23.6434 25.7273 23.7072 25.8276 23.7072 26.4295C23.7072 27.0132 23.6434 27.0953 23.1692 27.0953C21.0625 27.0953 18.9558 27.0953 16.8582 27.0953C16.3566 27.0953 16.2927 27.0132 16.3019 26.4022C16.3019 25.8185 16.3748 25.7273 16.8855 25.7273C17.9252 25.7182 18.974 25.7273 20.0137 25.7273Z"
          fill="#F84D2F"
        />
        <path
          d="M19.9955 25.1801C18.9467 25.1801 17.907 25.1801 16.8582 25.1801C16.3657 25.1801 16.2836 25.0707 16.2928 24.487C16.2928 23.8942 16.3566 23.803 16.8673 23.803C18.9558 23.803 21.0442 23.803 23.1327 23.803C23.6343 23.803 23.6981 23.8942 23.6981 24.4961C23.6981 25.0798 23.6161 25.1801 23.1236 25.1892C22.0839 25.1801 21.0442 25.1801 19.9955 25.1801Z"
          fill="#F84D2F"
        />
        <path
          d="M20.5062 1.94253C20.5062 2.38029 20.5062 2.81804 20.5062 3.26492C20.5062 3.60236 20.3511 3.83947 20.0046 3.83947C19.658 3.83947 19.5121 3.58412 19.5121 3.2558C19.5121 2.36205 19.5121 1.46829 19.5121 0.57454C19.5121 0.237103 19.658 -1.55633e-05 20.0228 0.00910436C20.3785 0.0182243 20.5153 0.255342 20.5153 0.59278C20.5062 1.03966 20.5062 1.49565 20.5062 1.94253Z"
          fill="#F84D2F"
        />
        <path
          d="M9.24304 4.29548C9.2248 3.86684 9.66256 3.55677 9.9544 3.803C10.7296 4.46876 11.4774 5.18011 12.1979 5.91883C12.2891 6.01003 12.2435 6.39306 12.1341 6.51162C12.0246 6.63018 11.6507 6.67578 11.5504 6.58458C10.7843 5.91883 10.0638 5.20747 9.32512 4.50524C9.26128 4.45052 9.26128 4.34108 9.24304 4.29548Z"
          fill="#F84D2F"
        />
        <path
          d="M30.8391 4.1769C30.7205 4.36842 30.6658 4.53258 30.5472 4.64202C29.9088 5.26218 29.2522 5.88233 28.6047 6.49337C28.3584 6.72136 28.094 6.7852 27.8477 6.52073C27.6106 6.26537 27.6653 6.00089 27.9116 5.76377C28.5591 5.15274 29.1975 4.52346 29.8723 3.93067C30.0091 3.81211 30.2645 3.74827 30.4378 3.78475C30.5837 3.81211 30.6931 4.02187 30.8391 4.1769Z"
          fill="#F84D2F"
        />
        <path
          d="M8.48613 13.1874C8.04837 13.1874 7.61062 13.1874 7.17286 13.1874C6.84454 13.1783 6.5983 13.0324 6.60742 12.6767C6.61654 12.3301 6.86278 12.1751 7.1911 12.1751C8.06661 12.1751 8.95124 12.1751 9.82676 12.1751C10.1733 12.1751 10.4378 12.3119 10.4287 12.6949C10.4287 13.0871 10.146 13.1874 9.7994 13.1874C9.36164 13.1874 8.92388 13.1874 8.48613 13.1874Z"
          fill="#F84D2F"
        />
        <path
          d="M31.4592 13.1874C31.0215 13.1874 30.5837 13.1874 30.1459 13.1874C29.8176 13.1874 29.5714 13.0415 29.5805 12.6767C29.5896 12.321 29.8267 12.1751 30.1551 12.1751C31.0488 12.166 31.9426 12.166 32.8363 12.1751C33.1646 12.1751 33.4018 12.3392 33.4018 12.6858C33.4018 13.0415 33.1555 13.1783 32.8272 13.1874C32.3621 13.1874 31.9152 13.1874 31.4592 13.1874Z"
          fill="#F84D2F"
        />
      </svg>
    </>
  );
};
export const TeamIcon = (props) => {
  return (
    <>
      <svg
        {...props}
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.8588 16.2193C11.9066 15.9225 12.0072 15.6367 12.1557 15.3753C11.8604 15.2509 11.5755 15.137 11.3237 15.0485C11.1827 14.999 11.0506 14.9271 10.9324 14.8357L9.82615 13.9932V13.5315C10.3556 13.0955 10.7618 12.5286 11.0044 11.887C11.28 11.8034 11.5215 11.6334 11.6932 11.4021C11.8648 11.1708 11.9576 10.8904 11.9578 10.6024V9.79588C11.9581 9.63809 11.9121 9.48369 11.8255 9.3518C11.7389 9.2199 11.6155 9.11631 11.4706 9.05385V7.68972C11.4738 7.2792 11.3804 6.87368 11.1978 6.50597C11.0153 6.13826 10.7487 5.81868 10.4198 5.57307C9.76121 5.08227 8.95789 4.82487 8.13672 4.84153C8.13672 4.84153 8.09624 4.83704 8.01979 4.83704C7.23864 4.85299 6.48153 5.11007 5.85218 5.57307C5.52321 5.81867 5.25667 6.13826 5.07412 6.50597C4.89156 6.87368 4.79811 7.2792 4.80134 7.68972V9.05385C4.65662 9.11656 4.5334 9.22021 4.44684 9.35205C4.36028 9.48389 4.31416 9.63816 4.31415 9.79588V10.6024C4.31466 10.8906 4.40773 11.171 4.57964 11.4023C4.75156 11.6335 4.99323 11.8035 5.26904 11.8871C5.51034 12.5289 5.91616 13.096 6.4458 13.5315V13.9932L5.33949 14.8357C5.22133 14.9271 5.08922 14.999 4.94824 15.0485C3.99035 15.3888 2.55875 16.0694 1.93965 16.3677C1.84525 16.414 1.76302 16.4818 1.69951 16.5657C1.636 16.6495 1.59298 16.747 1.57389 16.8504L0.625 21.7793C0.645344 21.917 0.702872 22.0466 0.791391 22.154C0.913411 22.2615 1.07103 22.3197 1.2336 22.3174H10.8785L11.8588 16.2193Z"
          fill="#F84D2F"
        />
        <path
          d="M16.8132 10.7043C17.0066 11.2018 17.2873 11.6607 17.6422 12.0594C17.9047 12.3825 18.2336 12.6452 18.6067 12.8299C18.9797 13.0146 19.3881 13.1169 19.8041 13.1298C20.2202 13.1428 20.6341 13.0661 21.0179 12.9049C21.4017 12.7438 21.7463 12.5019 22.0284 12.1958C22.0674 12.1539 22.1048 12.1119 22.1408 12.0684C22.5 11.6684 22.783 11.2061 22.9758 10.7043C23.2723 10.6145 23.532 10.4319 23.7169 10.1833C23.9018 9.93477 24.0019 9.63342 24.0026 9.32366V8.45872C24.0031 8.28899 23.954 8.12282 23.8613 7.98061C23.7686 7.83841 23.6364 7.72637 23.481 7.65824V6.19216C23.484 5.75072 23.383 5.31478 23.1861 4.91967C22.9892 4.52457 22.702 4.18144 22.3477 3.9181C21.6407 3.39094 20.7784 3.11406 19.8967 3.13111C19.8544 3.1265 19.8119 3.1245 19.7693 3.12511C18.9301 3.14293 18.1169 3.41992 17.4413 3.9181C17.0878 4.18177 16.8014 4.52512 16.6056 4.92025C16.4097 5.31539 16.3098 5.75116 16.314 6.19216V7.65824C16.1575 7.72553 16.0241 7.8372 15.9303 7.97946C15.8365 8.12172 15.7865 8.28833 15.7864 8.45872V9.32367C15.7871 9.63342 15.8872 9.93476 16.0721 10.1833C16.257 10.4319 16.5167 10.6145 16.8132 10.7043Z"
          fill="#F84D2F"
        />
        <path
          d="M38.2151 16.8504C38.196 16.747 38.153 16.6495 38.0895 16.5657C38.026 16.4818 37.9437 16.4141 37.8493 16.3677C37.2302 16.0694 35.7986 15.3888 34.8407 15.0485C34.6998 14.999 34.5677 14.9271 34.4495 14.8357L33.3432 13.9932V13.5315C33.8728 13.096 34.2786 12.5289 34.5199 11.887C34.7957 11.8035 35.0374 11.6335 35.2093 11.4022C35.3813 11.171 35.4743 10.8905 35.4748 10.6024V9.79588C35.4748 9.63816 35.4287 9.48389 35.3421 9.35205C35.2556 9.22021 35.1324 9.11656 34.9876 9.05385V7.68972C34.9909 7.2792 34.8974 6.87368 34.7149 6.50597C34.5323 6.13825 34.2658 5.81867 33.9368 5.57307C33.3075 5.11008 32.5503 4.85299 31.7692 4.83704C31.6927 4.83704 31.6523 4.84153 31.6523 4.84153C30.8311 4.82487 30.0278 5.08227 29.3692 5.57307C29.0402 5.81867 28.7737 6.13826 28.5912 6.50597C28.4086 6.87368 28.3152 7.2792 28.3184 7.68972V9.05385C28.1735 9.11631 28.0501 9.2199 27.9635 9.3518C27.8769 9.48369 27.8309 9.63809 27.8312 9.79588V10.6024C27.8314 10.8904 27.9242 11.1708 28.0958 11.4021C28.2675 11.6334 28.5089 11.8034 28.7846 11.8871C29.0272 12.5286 29.4334 13.0955 29.9628 13.5315V13.9932L28.8565 14.8357C28.7384 14.9271 28.6063 14.999 28.4653 15.0485C28.2135 15.137 27.9287 15.2509 27.6333 15.3753C27.7818 15.6367 27.8824 15.9225 27.9302 16.2193L28.9105 22.3174H38.5554C38.718 22.3197 38.8756 22.2615 38.9976 22.154C39.0861 22.0466 39.1437 21.917 39.164 21.7793L38.2151 16.8504Z"
          fill="#F84D2F"
        />
        <path
          d="M27.8496 22.3167L26.8955 16.3855C26.8586 16.1567 26.768 15.9399 26.6312 15.7528C26.4944 15.5657 26.3153 15.4137 26.1084 15.3091L22.9122 13.6887L20.8734 19.0973L20.3525 15.8167L20.6964 15.0776C20.6995 15.0708 20.7008 15.0633 20.7002 15.0558C20.6996 15.0483 20.6971 15.0411 20.693 15.0349C20.6889 15.0286 20.6833 15.0235 20.6766 15.02C20.67 15.0165 20.6626 15.0147 20.6551 15.0148H19.1346C19.1271 15.0147 19.1197 15.0165 19.113 15.02C19.1064 15.0235 19.1008 15.0286 19.0966 15.0349C19.0925 15.0411 19.0901 15.0483 19.0895 15.0558C19.0889 15.0633 19.0902 15.0708 19.0932 15.0776L19.4371 15.8167L18.9163 19.0973L16.8775 13.6887L13.6812 15.3091C13.4744 15.4137 13.2953 15.5657 13.1585 15.7528C13.0217 15.9399 12.9311 16.1567 12.8942 16.3855L11.9401 22.3167H27.8496Z"
          fill="#F84D2F"
        />
        <path
          d="M7.20899 26.7895L7.26844 27.0868L10.2456 27.1938C10.6937 28.6806 11.4808 30.0431 12.545 31.174L11.1495 33.8024L11.3755 34.0045C12.6448 35.1266 14.1263 35.9827 15.7323 36.5219L16.0177 36.617L17.5955 34.0917C19.1076 34.4485 20.682 34.4485 22.1941 34.0917L23.7759 36.617L24.0613 36.5219C25.6658 35.9817 27.1458 35.1257 28.4142 34.0045L28.6401 33.8024L27.2447 31.174C28.3108 30.0439 29.0993 28.6812 29.548 27.1938L32.5212 27.0868L32.5807 26.7895C32.7464 25.9619 32.83 25.12 32.8304 24.2761H6.95923C6.95965 25.12 7.04331 25.9619 7.20899 26.7895Z"
          fill="#F84D2F"
        />
      </svg>
    </>
  );
};
export const ClienIcon = (props) => {
  return (
    <>
      <svg
        {...props}
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.9812 11.2771C20.2713 11.2771 20.5495 11.1619 20.7546 10.9568C20.9597 10.7517 21.075 10.4735 21.075 10.1834V7.35901C21.075 7.06893 20.9597 6.79073 20.7546 6.58561C20.5495 6.38049 20.2713 6.26526 19.9812 6.26526C19.6911 6.26526 19.4129 6.38049 19.2078 6.58561C19.0027 6.79073 18.8875 7.06893 18.8875 7.35901V10.1834C18.8875 10.4735 19.0027 10.7517 19.2078 10.9568C19.4129 11.1619 19.6911 11.2771 19.9812 11.2771Z"
          fill="#F84D2F"
        />
        <path
          d="M32.6411 18.8875H29.8167C29.5266 18.8875 29.2484 19.0027 29.0433 19.2079C28.8382 19.413 28.723 19.6912 28.723 19.9813C28.723 20.2713 28.8382 20.5495 29.0433 20.7547C29.2484 20.9598 29.5266 21.075 29.8167 21.075H32.6411C32.9312 21.075 33.2094 20.9598 33.4145 20.7547C33.6196 20.5495 33.7348 20.2713 33.7348 19.9813C33.7348 19.6912 33.6196 19.413 33.4145 19.2079C33.2094 19.0027 32.9312 18.8875 32.6411 18.8875Z"
          fill="#F84D2F"
        />
        <path
          d="M10.1833 18.8875H7.35889C7.06881 18.8875 6.79061 19.0027 6.58549 19.2079C6.38037 19.413 6.26514 19.6912 6.26514 19.9813C6.26514 20.2713 6.38037 20.5495 6.58549 20.7547C6.79061 20.9598 7.06881 21.075 7.35889 21.075H10.1833C10.4733 21.075 10.7515 20.9598 10.9567 20.7547C11.1618 20.5495 11.277 20.2713 11.277 19.9813C11.277 19.6912 11.1618 19.413 10.9567 19.2079C10.7515 19.0027 10.4733 18.8875 10.1833 18.8875Z"
          fill="#F84D2F"
        />
        <path
          d="M1.94867 7.61318C2.0923 7.61318 2.23453 7.58489 2.36723 7.52992C2.49993 7.47496 2.6205 7.39439 2.72207 7.29283C2.82363 7.19127 2.9042 7.07069 2.95916 6.93799C3.01413 6.80529 3.04242 6.66306 3.04242 6.51943V3.36481H6.19673C6.48681 3.36481 6.76501 3.24957 6.97013 3.04446C7.17525 2.83934 7.29048 2.56114 7.29048 2.27106C7.29048 1.98098 7.17525 1.70278 6.97013 1.49766C6.76501 1.29254 6.48681 1.17731 6.19673 1.17731H1.94867C1.65859 1.17731 1.38039 1.29254 1.17527 1.49766C0.970153 1.70278 0.854919 1.98098 0.854919 2.27106V6.51943C0.854919 6.80951 0.970153 7.08771 1.17527 7.29283C1.38039 7.49795 1.65859 7.61318 1.94867 7.61318Z"
          fill="#F84D2F"
        />
        <path
          d="M38.0516 1.17731H33.8029C33.5128 1.17731 33.2346 1.29254 33.0295 1.49766C32.8244 1.70278 32.7092 1.98098 32.7092 2.27106C32.7092 2.56114 32.8244 2.83934 33.0295 3.04446C33.2346 3.24957 33.5128 3.36481 33.8029 3.36481H36.9579V6.51943C36.9579 6.80951 37.0731 7.08771 37.2782 7.29283C37.4833 7.49795 37.7615 7.61318 38.0516 7.61318C38.3417 7.61318 38.6199 7.49795 38.825 7.29283C39.0301 7.08771 39.1454 6.80951 39.1454 6.51943V2.27106C39.1454 1.98098 39.0301 1.70278 38.825 1.49766C38.6199 1.29254 38.3417 1.17731 38.0516 1.17731Z"
          fill="#F84D2F"
        />
        <path
          d="M6.19673 36.809H3.04242V33.6544C3.04242 33.3643 2.92719 33.0861 2.72207 32.881C2.51695 32.6759 2.23875 32.5607 1.94867 32.5607C1.65859 32.5607 1.38039 32.6759 1.17527 32.881C0.970153 33.0861 0.854919 33.3643 0.854919 33.6544V37.9028C0.854919 38.1929 0.970153 38.4711 1.17527 38.6762C1.38039 38.8813 1.65859 38.9965 1.94867 38.9965H6.19673C6.48681 38.9965 6.76501 38.8813 6.97013 38.6762C7.17525 38.4711 7.29048 38.1929 7.29048 37.9028C7.29048 37.6127 7.17525 37.3345 6.97013 37.1294C6.76501 36.9243 6.48681 36.809 6.19673 36.809Z"
          fill="#F84D2F"
        />
        <path
          d="M38.0516 32.5607C37.7615 32.5607 37.4833 32.6759 37.2782 32.881C37.0731 33.0861 36.9579 33.3643 36.9579 33.6544V36.809H33.8029C33.5128 36.809 33.2346 36.9243 33.0295 37.1294C32.8244 37.3345 32.7092 37.6127 32.7092 37.9028C32.7092 38.1929 32.8244 38.4711 33.0295 38.6762C33.2346 38.8813 33.5128 38.9965 33.8029 38.9965H38.0516C38.3417 38.9965 38.6199 38.8813 38.825 38.6762C39.0301 38.4711 39.1454 38.1929 39.1454 37.9028V33.6544C39.1454 33.3643 39.0301 33.0861 38.825 32.881C38.6199 32.6759 38.3417 32.5607 38.0516 32.5607Z"
          fill="#F84D2F"
        />
        <path
          d="M19.9812 28.7229C19.6911 28.7229 19.4129 28.8381 19.2078 29.0433C19.0027 29.2484 18.8875 29.5266 18.8875 29.8167V32.6411C18.8875 32.9312 19.0027 33.2094 19.2078 33.4145C19.4129 33.6196 19.6911 33.7348 19.9812 33.7348C20.2713 33.7348 20.5495 33.6196 20.7546 33.4145C20.9597 33.2094 21.075 32.9312 21.075 32.6411V29.8167C21.075 29.5266 20.9597 29.2484 20.7546 29.0433C20.5495 28.8381 20.2713 28.7229 19.9812 28.7229Z"
          fill="#F84D2F"
        />
        <path
          d="M20 2.41003C16.521 2.41003 13.1202 3.44166 10.2276 5.37446C7.33493 7.30726 5.0804 10.0544 3.74906 13.2685C2.41772 16.4827 2.06938 20.0194 2.74808 23.4315C3.42679 26.8436 5.10205 29.9778 7.56204 32.4378C10.022 34.8978 13.1562 36.5731 16.5683 37.2518C19.9804 37.9305 23.5172 37.5822 26.7313 36.2509C29.9454 34.9195 32.6926 32.665 34.6254 29.7724C36.5582 26.8797 37.5898 23.4789 37.5899 20C37.5899 17.69 37.1349 15.4027 36.2509 13.2686C35.367 11.1345 34.0713 9.19537 32.4379 7.56199C30.8046 5.92861 28.8655 4.63295 26.7314 3.74897C24.5972 2.865 22.3099 2.41003 20 2.41003ZM32.6411 22.6375H30.8952C30.4097 24.6401 29.3824 26.4706 27.9258 27.9283C26.4693 29.386 24.6397 30.4148 22.6375 30.902V32.6411C22.6375 33.3456 22.3576 34.0212 21.8595 34.5193C21.3613 35.0175 20.6857 35.2973 19.9812 35.2973C19.2767 35.2973 18.6011 35.0175 18.103 34.5193C17.6048 34.0212 17.325 33.3456 17.325 32.6411V30.8973C15.3268 30.4075 13.5016 29.378 12.0486 27.9214C10.5957 26.4647 9.57089 24.6369 9.0861 22.6375H7.35898C6.6545 22.6375 5.97887 22.3576 5.48073 21.8595C4.98258 21.3613 4.70273 20.6857 4.70273 19.9812C4.70273 19.2767 4.98258 18.6011 5.48073 18.103C5.97887 17.6048 6.6545 17.325 7.35898 17.325H9.09573C9.58577 15.3336 10.6118 13.5145 12.0625 12.0651C13.5133 10.6156 15.3332 9.59112 17.325 9.10278V7.35903C17.325 6.65455 17.6048 5.97893 18.103 5.48078C18.6011 4.98264 19.2767 4.70278 19.9812 4.70278C20.6857 4.70278 21.3613 4.98264 21.8595 5.48078C22.3576 5.97893 22.6375 6.65455 22.6375 7.35903V9.09816C24.6333 9.58386 26.4576 10.6077 27.9119 12.0582C29.3662 13.5088 30.3947 15.3305 30.8856 17.325H32.6411C33.3456 17.325 34.0212 17.6048 34.5194 18.103C35.0175 18.6011 35.2974 19.2767 35.2974 19.9812C35.2974 20.6857 35.0175 21.3613 34.5194 21.8595C34.0212 22.3576 33.3456 22.6375 32.6411 22.6375Z"
          fill="#F84D2F"
        />
        <path
          d="M26.0513 23.8204C25.4043 22.3993 24.2408 21.2776 22.797 20.6829C22.6889 20.6365 22.5969 20.5592 22.5326 20.4606C22.4682 20.3621 22.4344 20.2468 22.4354 20.1291V20.1229C22.4367 20.0395 22.4546 19.9573 22.488 19.8809C22.5214 19.8045 22.5697 19.7356 22.63 19.678C23.1642 19.1563 23.5309 18.4872 23.6834 17.7562C23.8358 17.0252 23.7671 16.2654 23.486 15.5736C23.2048 14.8818 22.724 14.2894 22.1048 13.872C21.4857 13.4545 20.7562 13.231 20.0094 13.2298C19.2627 13.2286 18.5325 13.4499 17.912 13.8654C17.2916 14.2809 16.8089 14.8717 16.5256 15.5627C16.2423 16.2536 16.1712 17.0132 16.3213 17.7447C16.4715 18.4762 16.8361 19.1464 17.3687 19.6698C17.4303 19.7279 17.4794 19.7979 17.5132 19.8756C17.547 19.9532 17.5648 20.0369 17.5654 20.1216V20.1329C17.5655 20.2496 17.5313 20.3637 17.4671 20.4612C17.4029 20.5587 17.3116 20.6352 17.2043 20.6813C15.7567 21.2785 14.5913 22.4054 13.946 23.8323C13.776 24.1924 13.6361 24.5659 13.5278 24.9492C13.4746 25.1414 13.472 25.344 13.52 25.5376C13.5681 25.7311 13.6653 25.9089 13.8023 26.0539C14.8711 27.1873 16.2315 28.0045 17.7341 28.4157C17.9706 28.0315 18.3016 27.7144 18.6955 27.4946C19.0894 27.2748 19.5331 27.1597 19.9841 27.1602C20.4352 27.1607 20.8786 27.2768 21.2721 27.4974C21.6655 27.7181 21.9958 28.0359 22.2314 28.4205C23.7503 28.0083 25.1242 27.1805 26.1985 26.0303C26.3342 25.8851 26.4304 25.7075 26.4777 25.5145C26.525 25.3215 26.5219 25.1196 26.4687 24.9281C26.3609 24.5478 26.2212 24.1773 26.0513 23.8204Z"
          fill="#F84D2F"
        />
      </svg>
    </>
  );
};
export const RecordIcon = (props) => {
  return (
    <>
      <svg
        {...props}
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.3689 16.3569C29.5452 16.3569 31.6518 16.3569 33.8455 16.3569C33.8455 16.531 33.8455 16.6964 33.8455 16.8618C33.8455 24.3308 33.8455 31.7998 33.8542 39.2688C33.8542 39.6953 33.7062 39.765 33.3319 39.765C31.5212 39.7476 29.7105 39.7476 27.8912 39.765C27.4733 39.7737 27.3602 39.6518 27.3602 39.234C27.3776 33.5495 27.3689 27.8651 27.3689 22.1807C27.3689 20.4309 27.3689 18.6812 27.3689 16.9315C27.3689 16.7574 27.3689 16.5833 27.3689 16.3569Z"
          fill="#F84D2F"
        />
        <path
          d="M15.556 39.7302C15.3994 39.7389 15.2688 39.7563 15.1469 39.7563C13.2927 39.7563 11.4385 39.7476 9.57563 39.765C9.21001 39.765 9.11426 39.6605 9.11426 39.3036C9.13167 33.0272 9.13167 26.7508 9.12296 20.4657C9.12296 20.0653 9.27095 19.9783 9.63656 19.987C10.9336 20.0044 12.2394 19.9957 13.5365 19.9957C14.198 19.9957 14.8596 19.9957 15.556 19.9957C15.556 26.6203 15.556 33.1578 15.556 39.7302Z"
          fill="#F84D2F"
        />
        <path
          d="M18.2633 39.765C18.2633 34.9337 18.2633 30.172 18.2633 25.3842C20.4048 25.3842 22.5288 25.3842 24.6964 25.3842C24.6964 25.5583 24.6964 25.6975 24.6964 25.8368C24.6964 30.3025 24.6877 34.777 24.7051 39.2427C24.7051 39.6692 24.5833 39.7737 24.1741 39.7737C22.3722 39.7476 20.5789 39.765 18.7769 39.765C18.6202 39.765 18.4636 39.765 18.2633 39.765Z"
          fill="#F84D2F"
        />
        <path
          d="M6.4505 27.5256C6.4505 27.7084 6.4505 27.8825 6.4505 28.0566C6.4505 31.7824 6.44179 35.5081 6.4592 39.2426C6.4592 39.6605 6.35474 39.7824 5.92819 39.7737C4.10012 39.7475 2.27204 39.7562 0.443968 39.7737C0.113174 39.7737 -0.00869751 39.6866 -0.00869751 39.3384C7.60425e-06 35.5256 7.60425e-06 31.7127 7.60425e-06 27.8912C7.60425e-06 27.6561 7.59587e-06 27.4733 0.330802 27.4733C2.30686 27.482 4.27422 27.482 6.25028 27.482C6.29381 27.482 6.33733 27.4994 6.4505 27.5256Z"
          fill="#F84D2F"
        />
        <path
          d="M29.7802 11.8738C29.0925 12.5441 28.5006 13.1099 27.9173 13.6932C26.1328 15.4603 24.3482 17.2187 22.5724 18.9859C22.494 19.0642 22.4244 19.16 22.346 19.247C21.876 19.7781 21.3711 19.8564 20.7617 19.4908C18.1328 17.9326 15.4951 16.3744 12.8749 14.8074C12.5441 14.6072 12.3352 14.6246 12.0392 14.8684C9.36671 17.0708 6.67683 19.2557 3.98695 21.4494C3.85637 21.5626 3.71709 21.6758 3.5604 21.7541C3.2209 21.9195 2.81176 21.815 2.58543 21.5104C2.34168 21.2057 2.35909 20.7704 2.62025 20.4745C2.7073 20.3787 2.81176 20.2917 2.90752 20.2133C5.81502 17.8368 8.72253 15.4603 11.63 13.0838C12.1262 12.6747 12.4135 12.6486 12.9706 12.9794C15.6431 14.5463 18.3243 16.1219 20.9967 17.6975C21.2231 17.8281 21.3798 17.8716 21.5974 17.654C23.8346 15.4168 26.0805 13.197 28.3265 10.9685C28.3874 10.9162 28.4222 10.8379 28.5354 10.7073C28.0044 10.7073 27.543 10.7073 27.0903 10.7073C26.481 10.6986 26.1154 10.3939 26.1066 9.89775C26.0979 9.41026 26.481 9.08817 27.0729 9.07947C28.2046 9.07076 29.3362 9.07076 30.4679 9.07947C31.1295 9.07947 31.4081 9.36674 31.4081 10.0457C31.4081 11.1774 31.4081 12.3091 31.4081 13.4407C31.4081 14.0153 31.1034 14.3635 30.6072 14.3722C30.0936 14.3809 29.7889 14.024 29.7802 13.4146C29.7802 12.9445 29.7802 12.4832 29.7802 11.8738Z"
          fill="#F84D2F"
        />
        <path
          d="M30.346 5.04898C30.346 2.35039 32.4875 0.21764 35.1861 0.226345C37.876 0.226345 40.0087 2.3678 40.0087 5.07509C40.0087 7.76497 37.8411 9.92384 35.1687 9.90643C32.4701 9.89773 30.3373 7.74756 30.346 5.04898ZM36.4309 3.58652C36.753 2.84658 36.7095 2.74212 35.9173 2.62896C35.6474 2.59414 35.5169 2.50708 35.5604 2.22852C35.5952 1.93255 35.4211 1.90643 35.1861 1.89773C34.9423 1.89773 34.7769 1.94125 34.8205 2.22852C34.864 2.5245 34.7421 2.65507 34.4548 2.75953C33.9064 2.94234 33.5495 3.33407 33.506 3.93472C33.4625 4.56149 33.8368 4.92711 34.3504 5.17956C34.6202 5.31884 34.9162 5.40589 35.1948 5.52776C35.4821 5.65834 35.7867 5.79762 35.691 6.18935C35.6039 6.56367 35.2644 6.60719 34.9597 6.58108C34.5506 6.54626 34.1415 6.4418 33.7149 6.36345C33.3319 7.05986 33.3928 7.22526 34.1676 7.38195C34.2546 7.39936 34.3417 7.43418 34.42 7.43418C34.6812 7.43418 34.7943 7.55605 34.7595 7.8085C34.7247 8.10447 34.8292 8.21764 35.1426 8.21764C35.4559 8.21764 35.5169 8.06965 35.4821 7.8085C35.4472 7.53864 35.543 7.39936 35.8216 7.31231C36.4918 7.09468 36.8575 6.6159 36.8749 6.02395C36.8923 5.40589 36.5789 4.97063 35.8999 4.67466C35.6039 4.54408 35.2905 4.46574 35.0294 4.29163C34.864 4.18717 34.6986 3.94343 34.7073 3.76933C34.716 3.65616 35.012 3.45594 35.1861 3.45594C35.5952 3.45594 35.9957 3.54299 36.4309 3.58652Z"
          fill="#F84D2F"
        />
      </svg>
    </>
  );
};
export const Img1Right = (props) => {
  return (
    <>
      <svg
        {...props}
        width="106"
        height="156"
        viewBox="0 0 106 156"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M56 0L99.3013 25V75L56 100L12.6987 75V25L56 0Z"
          fill="#F84D2F"
        />
        <path
          d="M28 100L52.2487 114V142L28 156L3.75129 142V114L28 100Z"
          fill="#F84D2F"
        />
        <path
          opacity="0.2"
          d="M81.5 101L100.12 111.75V133.25L81.5 144L62.8805 133.25V111.75L81.5 101Z"
          fill="#F84D2F"
        />
      </svg>
    </>
  );
};
export const Img1Left = (props) => {
  return (
    <>
      <svg
        {...props}
        width="88"
        height="88"
        viewBox="0 0 88 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M71.1692 18.8434L77.3079 41.7532L60.5368 58.5243L37.627 52.3856L31.4883 29.4759L48.2594 12.7047L71.1692 18.8434Z"
          fill="#F84D2F"
        />
        <path
          d="M28.2352 42.9938L31.6728 55.8232L22.281 65.2151L9.45149 61.7774L6.01385 48.9479L15.4057 39.5561L28.2352 42.9938Z"
          fill="#F84D2F"
        />
        <path
          opacity="0.2"
          d="M45.8448 61.2743L48.4844 71.1255L41.2729 78.3371L31.4217 75.6975L28.782 65.8463L35.9936 58.6347L45.8448 61.2743Z"
          fill="#F84D2F"
        />
      </svg>
    </>
  );
};
export const Img2Left = (props) => {
  return (
    <>
      <svg
        {...props}
        width="106"
        height="156"
        viewBox="0 0 106 156"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M50 0L6.69873 25V75L50 100L93.3013 75V25L50 0Z"
          fill="#F84D2F"
        />
        <path
          d="M78 100L53.7513 114V142L78 156L102.249 142V114L78 100Z"
          fill="#F84D2F"
        />
        <path
          opacity="0.2"
          d="M24.5 101L5.88046 111.75V133.25L24.5 144L43.1195 133.25V111.75L24.5 101Z"
          fill="#F84D2F"
        />
      </svg>
    </>
  );
};
export const Img2Right = (props) => {
  return (
    <>
      <svg
        {...props}
        width="89"
        height="88"
        viewBox="0 0 89 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M19.4578 16.8309L42.3675 10.6923L59.1387 27.4634L53 50.3732L30.0902 56.5118L13.3191 39.7407L19.4578 16.8309Z"
          fill="#F84D2F"
        />
        <path
          d="M43.6081 59.7649L56.4376 56.3273L65.8294 65.7191L62.3918 78.5486L49.5623 81.9862L40.1705 72.5944L43.6081 59.7649Z"
          fill="#F84D2F"
        />
        <path
          opacity="0.2"
          d="M61.8887 42.1554L71.7399 39.5157L78.9515 46.7273L76.3119 56.5785L66.4607 59.2181L59.2491 52.0066L61.8887 42.1554Z"
          fill="#F84D2F"
        />
      </svg>
    </>
  );
};
export const Img3Right = (props) => {
  return (
    <>
      <svg
        {...props}
        width="186"
        height="186"
        viewBox="0 0 186 186"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M145.664 149.907L97.3677 162.848L62.0123 127.492L74.9533 79.1959L123.25 66.255L158.605 101.61L145.664 149.907Z"
          fill="#F84D2F"
        />
        <path
          d="M94.7523 59.3969L67.7064 66.6438L47.9074 46.8448L55.1543 19.7989L82.2002 12.552L101.999 32.351L94.7523 59.3969Z"
          fill="#F84D2F"
        />
        <path
          opacity="0.2"
          d="M56.2149 96.5199L35.4475 102.085L20.2447 86.8817L25.8093 66.1143L46.5767 60.5497L61.7795 75.7525L56.2149 96.5199Z"
          fill="#F84D2F"
        />
      </svg>
    </>
  );
};
export const Img3Left = (props) => {
  return (
    <>
      <svg
        {...props}
        width="88"
        height="88"
        viewBox="0 0 88 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M71.1095 18.7836L77.2482 41.6934L60.477 58.4645L37.5673 52.3258L31.4286 29.416L48.1997 12.6449L71.1095 18.7836Z"
          fill="#F84D2F"
        />
        <path
          d="M28.1755 42.9341L31.6132 55.7635L22.2213 65.1554L9.39186 61.7177L5.95422 48.8882L15.346 39.4964L28.1755 42.9341Z"
          fill="#F84D2F"
        />
        <path
          opacity="0.2"
          d="M45.7851 61.2145L48.4247 71.0657L41.2131 78.2773L31.3619 75.6377L28.7223 65.7865L35.9339 58.5749L45.7851 61.2145Z"
          fill="#F84D2F"
        />
      </svg>
    </>
  );
};
