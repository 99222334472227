import React, { useEffect, useState } from 'react';
import CloseIcon from '../assets/images/CloseIcon.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PLLogo } from '../assets/icons/icon';

const MobileMenu = ({ setIsMobileMenuOpen, outsideRef, nodeRef }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [MenuList, setMenuList] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(
    MenuList.length > 0 && MenuList[0].name
  );
  useEffect(() => {
    setMenuList(getTranslatedList(t));
  }, [t]);

  const handleClickScroll = (id) => {
    setSelectedMenu();
    if (location.pathname !== '/') {
      navigate('/');
    }

    setTimeout(() => {
      const element = document.getElementById(id);

      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - 80;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }, 700);
  };

  return (
    <div
      ref={nodeRef}
      className="z-[999] fixed top-0 flex h-screen w-[100vw] !overflow-hidden"
    >
      <div ref={outsideRef} className="w-full bg-white">
        <div className="mobilemenu-shadow FLEX_CENTER h-[66px] justify-between px-[40px] max_sm:pl-[20px] max_sm:pr-[15px] w-[100%]">
          <div className="w-[140px] max_sm:w-[100px]">
            <PLLogo color="#333333" width="120" />
          </div>
          <div className="flex items-center gap-2">
            <div
              className="cursor-pointer "
              onClick={() => setIsMobileMenuOpen(false)}
            >
              <img draggable="false" src={CloseIcon} alt="CloseIcon" />
            </div>
          </div>
        </div>

        <div className="w-full bg-white h-[calc(100vh-66px)] z-[100] overflow-y-scroll mt-1">
          {MenuList.map((menu, index) => (
            <React.Fragment key={index}>
              <div
                className={`flex w-full items-center border-[#00000033] no-underline`}
                // to={menu.link}
                onClick={() => {
                  setIsMobileMenuOpen(false);
                  handleClickScroll(menu.link);
                  setSelectedMenu(menu.name);
                }}
              >
                {/* {({ isActive }) => ( */}
                <div
                  className={`flex w-full flex-col ${
                    selectedMenu === menu.name ? 'bg-BgTernary' : ''
                  }`}
                >
                  <div
                    className={`cursor-pointer Text16Normal hover:text-PrimaryT hover:font-medium p-5`}
                  >
                    {menu.name}
                  </div>
                </div>
                {/* )} */}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
const getTranslatedList = (t) => {
  // Define your lists here
  const lists = [
    {
      name: t('OurStory'),
      link: 'OurStoryId',
    },
    {
      name: t('WhyPalmLabs'),
      link: 'WhyPalmLabsId',
    },
    {
      name: t('DCDial'),
      link: 'DCDialId',
    },
    {
      name: t('Avnovo'),
      link: 'AvnovoId',
    },
    {
      name: t('Epifony'),
      link: 'EpifonyId',
    },
  ];

  return lists;
};

export default MobileMenu;
