import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ClienIcon,
  InnovationIcon,
  RecordIcon,
  TeamIcon,
} from '../../assets/icons/HomeIcons';
import CommonSection from '../Common/CommonSection';
import { useWindowSize } from '../Hooks/useWindowSize';

const OurServices = ({ sectionRefs }) => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;
  const [services] = useState([
    {
      title: t('Innovation'),
      text: t('InnovationT'),
      icon: <InnovationIcon />,
    },
    {
      title: t('ExpertTeam'),
      text: t('ExpertTeamT'),
      icon: <TeamIcon />,
    },
    {
      title: t('ClientCentric'),
      text: t('ClientCentricT'),
      icon: <ClienIcon />,
    },
    {
      title: t('ProvenTrack'),
      text: t('ProvenTrackT'),
      icon: <RecordIcon />,
    },
  ]);
  return (
    <CommonSection>
      <p className="Text24 text-PrimaryT" id="WhyPalmLabsId" ref={sectionRefs}>
        {t('OurServices')}
      </p>
      <h2 className="Text50Bold text-PrimaryT max-w-[740px] mt-5">
        {t('Why')}
        <span className="text-ButtonPrimary"> {t('PalmLabs')}</span>
      </h2>
      <div className="mt-[90px] max_md:mt-[70px]  max_sm:mt-[60px] flex justify-between flex-wrap gap-y-[60px] max_md:gap-x-5">
        {services.map((service, index) => (
          <div className="relative" key={index}>
            <div className="absolute left-[30px] top-[-30px] p-[10px] bg-BgTernary rounded z-10">
              {React.cloneElement(service.icon, {
                style: {
                  width: isMobileView ? 30 : 40,
                  height: isMobileView ? 30 : 40,
                },
              })}
            </div>
            <div
              className="max-w-[560px] max_xl:max-w-[470px] max_md:max-w-[370px] max_880:max-w-[344px] max_800:max-w-[100%] bg-BgGrey px-[30px] pt-[50px] pb-[30px]"
              style={{
                clipPath:
                  'polygon(0 0, 90% 0, 100% 18%, 100% 100%, 10% 100%, 0 82%)',
              }}
            >
              <p className="Text24 font-semibold text-PrimaryT">
                {service.title}
              </p>
              <p className="font-OutfitFont text-textSmall font-normal text-TernaryT mt-3 max_xl:min-h-[96px] max_800:min-h-0 max_md:text-textExtraSmall max_sm:text-[12px]">
                {service.text}
              </p>
            </div>
          </div>
        ))}
      </div>
    </CommonSection>
  );
};

export default OurServices;
