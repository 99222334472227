import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import { useCookies } from 'react-cookie';
import { CSSTransition } from 'react-transition-group';
import Footer from './Footer';
import Frame1 from '../assets/images/Frame12.png';
import MobileMenu from './MobileMenu';
import ModalCustom from './Common/ModalCustom';
import ManageCookies from './Cookies/ManageCookies';
import CookiesConsent from './Cookies/CookiesConsent';
import moment from 'moment';

const Layout = ({ children }) => {
  const location = useLocation();
  const nodeRef = useRef(null);
  const outsideRef = useRef(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [navbarChangeColor, setNavbarChangeColor] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [manageClicked, setManageClicked] = useState(false);
  const [cookies, setCookie] = useCookies([
    'cookie_consent_palmlab',
    'analytics',
    'functional',
    'targeting_cookie',
  ]);

    useEffect(() => {
      if (cookies.cookie_consent_palmlab === undefined) {
        setIsModalOpen(true);
      }
      // setCookie('cookie_consent',true);
    }, []);
  function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = React.useState(null);

    React.useEffect(() => {
      let lastScrollY = window.pageYOffset;

      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        const direction = scrollY > lastScrollY ? 'down' : 'up';
        if (
          direction !== scrollDirection &&
          (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
        ) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener('scroll', updateScrollDirection); // add event listener
      return () => {
        window.removeEventListener('scroll', updateScrollDirection); // clean up
      };
    }, [scrollDirection]);

    return scrollDirection;
  }
  const scrollDirection = useScrollDirection();

  const changeBackground = () => {
    if (window.scrollY >= 20) {
      setNavbarChangeColor(true);
    } else {
      setNavbarChangeColor(false);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', changeBackground);
      // cleanup function
      return () => {
        window.removeEventListener('scroll', changeBackground);
      };
    }
  }, []);


  const handleSelection = (id, data) => {
    if (id === 'accept') {
      setCookie('cookie_consent_palmlab', true, {
        expires: moment().add(1, 'months').toDate(),
      });
      setIsModalOpen(false);
    } else if (id === 'reject') {
      setCookie('cookie_consent_palmlab', false, {
        expires: moment().add(1, 'months').toDate(),
      });
      setIsModalOpen(false);
    } else if (id === 'allow-all') {
      setCookie('cookie_consent_palmlab', true, {
        expires: moment().add(1, 'months').toDate(),
      });
      setCookie('analytics', true, {
        expires: moment().add(1, 'months').toDate(),
      });
      setCookie('functional', true, {
        expires: moment().add(1, 'months').toDate(),
      });
      setCookie('targeting_cookie', true, {
        expires: moment().add(1, 'months').toDate(),
      });
      setIsModalOpen(false);
    } else if (id === 'reject-all') {
      setCookie('cookie_consent_palmlab', false, {
        expires: moment().add(1, 'months').toDate(),
      });
      setCookie('analytics', false, {
        expires: moment().add(1, 'months').toDate(),
      });
      setCookie('functional', false, {
        expires: moment().add(1, 'months').toDate(),
      });
      setCookie('targeting_cookie', false, {
        expires: moment().add(1, 'months').toDate(),
      });
      setIsModalOpen(false);
    } else {
      setCookie('cookie_consent_palmlab', true, {
        expires: moment().add(1, 'months').toDate(),
      });
      data.forEach((cookie) => {
        if (cookie.checked) {
          setCookie(cookie.id, true, {
            expires: moment().add(1, 'months').toDate(),
          });
        } else {
          setCookie(cookie.id, false, {
            expires: moment().add(1, 'months').toDate(),
          });
        }
      });
      setIsModalOpen(false);
    }
  };
  return (
    <>
      <div className="relative w-full">
        <div
          className={`sticky z-40 ${
            scrollDirection === 'down' ? '-top-24' : 'top-0'
          } transition-all duration-500`}
        >
          <div
            className={`relative m-auto max-w-[1920px] overflow-hidden w-full ${
              navbarChangeColor
                ? 'bg-white shadow-sm transition'
                : location.pathname === '/'
                ? 'bg-transparent bg-PrimaryT'
                : 'bg-transparent transition'
            }`}
          >
            {!navbarChangeColor && location.pathname === '/' && (
              <div className="absolute top-5 left-0">
                <img src={Frame1} alt="F1" />
              </div>
            )}
            <Navbar
              setIsMobileMenuOpen={setIsMobileMenuOpen}
              navbarChangeColor={navbarChangeColor}
            />

            <CSSTransition
              in={isMobileMenuOpen}
              nodeRef={nodeRef}
              timeout={300}
              classNames="menu"
              type={'out-in' | 'in-out'}
              unmountOnExit
              onEnter={() => setIsMobileMenuOpen(true)}
              onExited={() => setIsMobileMenuOpen(false)}
            >
              <MobileMenu
                nodeRef={nodeRef}
                outsideRef={outsideRef}
                setIsMobileMenuOpen={setIsMobileMenuOpen}
              />
            </CSSTransition>
          </div>
        </div>
        <div>{children}</div>
        <div>
          <Footer />
        </div>
      </div>
      <ModalCustom
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        manageClicked={manageClicked}
      >
        {manageClicked ? (
          <>
            <ManageCookies
              setManageClicked={setManageClicked}
              handleSelection={handleSelection}
            />
          </>
        ) : (
          <>
            <CookiesConsent
              handleReject={() => setIsModalOpen(false)}
              handleManage={() => setManageClicked(true)}
              handleSelection={handleSelection}
            />
          </>
        )}
      </ModalCustom>
    </>
  );
};

export default Layout;
