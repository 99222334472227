export const PLLogo = ({ color = '#FFFFFF',width="178" }) => {
  return (
    <>
      <svg
        width={width}
        height="30"
        viewBox="0 0 178 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_219_2387)">
          <path
            opacity="0.7"
            d="M4.06498 15.0828C3.89944 17.6579 5.84917 19.8467 8.40588 20.0123C10.466 20.141 12.2501 18.927 12.9859 17.1245C12.6364 15.653 12.8939 14.0527 13.8872 12.7468C15.6346 10.4476 18.9086 9.98774 21.2262 11.7351C23.5438 13.4825 23.9853 16.7566 22.2379 19.0742C21.3734 20.2146 20.1226 20.9136 18.7983 21.0975C18.0441 21.87 17.5475 22.8633 17.4739 24.0221C17.3084 26.5788 19.2581 28.786 21.8148 28.9516C24.3899 29.1171 26.5788 27.1674 26.7443 24.6107C26.7995 23.7278 26.6156 22.9001 26.2293 22.1827C26.2293 22.1643 26.2293 22.1643 26.2109 22.1275C26.1925 22.0724 26.1557 22.0356 26.1373 21.962C26.0638 21.7964 25.9534 21.6493 25.843 21.5021V21.4838C25.0153 19.9939 24.5923 18.2649 24.7026 16.4071C24.813 14.5494 25.4936 12.8571 26.542 11.4592L26.5604 11.4408C26.634 11.3305 26.7075 11.2201 26.8363 11.1098C26.8363 11.0546 26.8915 11.0178 26.9099 10.981C27.4249 10.3004 27.7192 9.47272 27.7928 8.57143C27.9583 6.01472 26.027 3.80748 23.4519 3.64194C21.5205 3.51319 19.8099 4.59841 19.0006 6.19865C17.3084 9.2336 13.9791 11.1833 10.3004 10.9442C10.1349 10.9258 9.95094 10.9258 9.7854 10.889C9.56468 10.8338 9.30717 10.7971 9.04966 10.7787H9.03126C6.43776 10.5763 4.23053 12.5261 4.06498 15.0828Z"
            fill="#0BA94B"
          />
          <path
            opacity="0.7"
            d="M17.9705 29.0803C18.6327 28.5653 19.1109 27.9215 19.3868 27.1858C19.4052 27.1674 19.4052 27.1674 19.4052 27.149C19.4236 27.1122 19.442 27.0386 19.4788 26.9835C19.5523 26.8179 19.5891 26.6524 19.6259 26.4868L19.6443 26.4684C20.1961 24.9234 21.171 23.5439 22.5689 22.4586C23.9668 21.3734 25.6222 20.7848 27.2777 20.6561H27.3145C27.4432 20.6377 27.572 20.6193 27.7191 20.6377C27.7559 20.6193 27.8111 20.6193 27.8663 20.6009C28.6756 20.5273 29.4665 20.233 30.1471 19.718C32.0784 18.2281 32.4647 15.4507 30.9564 13.5009C29.8344 12.0478 27.9766 11.4776 26.3396 11.9191C23.1023 12.618 19.6075 11.4592 17.4555 8.66341C17.3635 8.53465 17.2531 8.4059 17.1796 8.25875C17.0876 8.05642 16.9405 7.85409 16.7933 7.65176L16.7749 7.63336C15.285 5.70203 12.4892 5.33416 10.5579 6.82404C8.60814 8.33232 8.27705 11.1282 9.74854 13.0595C10.7418 14.347 12.2869 14.9356 13.7951 14.7517C15.1011 12.3237 18.0809 11.4041 20.5088 12.6916C22.808 13.9056 23.7461 16.6462 22.7712 19.0006C22.7712 19.019 22.7528 19.0374 22.7528 19.0742C22.6977 19.1846 22.6609 19.3133 22.6057 19.4237C22.4953 19.626 22.3666 19.8099 22.2194 19.9939C22.201 20.0123 22.201 20.0307 22.1826 20.0491C20.7479 21.9988 18.0625 22.6794 15.8552 21.5206C15.6713 21.4286 15.5057 21.2998 15.3402 21.1711C14.3837 21.1527 13.3905 21.4286 12.5812 22.054C10.6498 23.5439 10.282 26.3397 11.7718 28.271C13.2617 30.2207 16.0392 30.5702 17.9705 29.0803Z"
            fill="#FCB81D"
          />
          <path
            opacity="0.7"
            d="M13.0778 4.98472C13.0594 5.00311 13.0594 5.00311 13.0778 5.0399C13.0778 5.09508 13.0778 5.16866 13.0594 5.26062C13.041 5.48135 13.0594 5.68368 13.0778 5.88601L13.0594 5.9044C12.9675 7.89091 12.3421 9.84063 11.1097 11.588C9.87732 13.3354 8.16672 14.623 6.29057 15.3771H6.25378C6.10663 15.4323 5.95948 15.5059 5.77555 15.5427C5.73876 15.5794 5.66518 15.5978 5.61 15.6346C4.69032 16.0025 3.8994 16.6279 3.29241 17.474C1.5818 19.8835 2.13361 23.2312 4.57996 24.9602C6.40093 26.2477 8.75531 26.2293 10.5027 25.1441C13.9975 23.176 18.4671 23.2864 21.9435 25.7511C22.0907 25.8615 22.2746 25.9902 22.4034 26.119C22.5873 26.3213 22.808 26.5053 23.0655 26.6892L23.0839 26.7076C25.4935 28.4182 28.8595 27.8296 30.5701 25.42C32.2991 22.9921 31.6922 19.6444 29.2826 17.9338C27.48 16.6647 25.1992 16.6463 23.4334 17.7131C23.2127 18.3385 22.9 18.9639 22.4402 19.5157C20.3249 22.1092 16.5174 22.4954 13.9239 20.3802C11.3304 18.2649 10.9442 14.4574 13.0594 11.8639C14.9172 9.60152 18.0625 9.01292 20.564 10.3373C21.4285 9.951 22.201 9.34401 22.7896 8.51629C24.5003 6.10673 23.9117 2.7407 21.5021 1.03009C19.0741 -0.698908 15.7265 -0.091919 14.0159 2.31764C13.4457 3.10857 13.133 4.02825 13.0778 4.98472Z"
            fill="#EC1C24"
          />
          <path
            opacity="0.7"
            d="M26.3212 3.53158C27.2964 3.53158 28.087 2.74101 28.087 1.76579C28.087 0.79057 27.2964 0 26.3212 0C25.346 0 24.5554 0.79057 24.5554 1.76579C24.5554 2.74101 25.346 3.53158 26.3212 3.53158Z"
            fill="#FCB81D"
          />
          <path
            opacity="0.7"
            d="M34.6351 22.7346C36.169 22.7346 37.4125 21.4911 37.4125 19.9571C37.4125 18.4232 36.169 17.1797 34.6351 17.1797C33.1012 17.1797 31.8577 18.4232 31.8577 19.9571C31.8577 21.4911 33.1012 22.7346 34.6351 22.7346Z"
            fill="#0BA94B"
          />
          <path
            opacity="0.7"
            d="M4.0466 11.0178C6.28147 11.0178 8.09319 9.20603 8.09319 6.97116C8.09319 4.73628 6.28147 2.92456 4.0466 2.92456C1.81172 2.92456 0 4.73628 0 6.97116C0 9.20603 1.81172 11.0178 4.0466 11.0178Z"
            fill="#EC1C24"
          />
          <path
            d="M46.2415 24.4083H40.8889V3.67871H48.4855C53.3782 3.67871 56.3395 5.95952 56.3395 10.2636C56.3395 15.1011 52.8264 17.5291 47.8233 17.5291H46.2415V24.4083ZM47.4738 13.3721C49.5339 13.3721 50.8399 12.7468 50.8399 10.5947C50.8399 8.47945 49.7179 7.89085 47.7497 7.89085H46.2599V13.3721H47.4738Z"
            fill={color}
          />
          <path
            d="M71.1465 21.0423L71.496 24.4083H67.0631L66.8424 22.9368L66.6585 22.9C65.7204 24.3715 64.2673 24.8681 62.6303 24.8681C57.8663 24.8681 56.6523 21.0423 56.6523 17.1428C56.6523 11.6615 59.4298 7.98279 65.095 7.98279C66.217 7.98279 68.6082 8.25869 71.1465 9.23355V21.0423ZM65.9779 11.8822C65.6284 11.7535 65.1134 11.6615 64.5432 11.6615C62.5199 11.6615 61.8393 14.0895 61.8393 16.7749C61.8393 19.1661 62.2808 21.171 64.0834 21.171C65.2973 21.171 65.9779 20.3065 65.9779 19.3868V11.8822Z"
            fill={color}
          />
          <path
            d="M79.534 2.7406V18.7614C79.534 20.1594 79.8835 20.8215 80.8767 20.8215C81.3366 20.8215 81.962 20.6008 82.6793 20.2881L83.8013 23.7461C82.7161 24.4635 80.84 24.9049 79.442 24.9049C74.6045 24.9049 74.3286 22.1643 74.3286 19.1661V2.7406H79.534Z"
            fill={color}
          />
          <path
            d="M85.1992 24.4083V11.6615L84.8313 8.44263H89.1354L89.4849 10.1348C90.4597 8.82889 91.7289 7.98279 93.789 7.98279C95.5916 7.98279 97.0631 8.60817 97.9092 10.0429C98.8472 8.77371 100.19 7.98279 102.176 7.98279C105.23 7.98279 107.106 9.6934 107.106 13.5009V24.4083H101.901V14.4022C101.901 13.0042 101.717 11.9742 100.466 11.9742C99.2519 11.9742 98.7553 12.9491 98.7553 14.2734V24.4083H93.5499V14.4022C93.5499 13.0226 93.4211 11.9742 92.152 11.9742C90.9012 11.9742 90.4414 12.9491 90.4414 14.3102V24.4083H85.1992Z"
            fill={color}
          />
          <path
            d="M116.505 3.67871H121.894V19.6996H130.153V24.4083H116.505V3.67871Z"
            fill={color}
          />
          <path
            d="M145.917 21.0423L146.266 24.4083H141.833L141.612 22.9368L141.428 22.9C140.49 24.3715 139.037 24.8681 137.4 24.8681C132.636 24.8681 131.422 21.0423 131.422 17.1428C131.422 11.6615 134.2 7.98279 139.865 7.98279C140.987 7.98279 143.378 8.25869 145.917 9.23355V21.0423ZM140.748 11.8822C140.398 11.7535 139.883 11.6615 139.313 11.6615C137.29 11.6615 136.609 14.0895 136.609 16.7749C136.609 19.1661 137.051 21.171 138.853 21.171C140.067 21.171 140.748 20.3065 140.748 19.3868V11.8822Z"
            fill={color}
          />
          <path
            d="M149.062 2.7406H154.304V8.71853C154.985 8.31387 156.088 7.96439 157.302 7.96439C161.165 7.96439 163.574 10.5211 163.574 15.7265C163.574 21.7964 160.705 24.8865 155.628 24.8865C153.256 24.8865 150.46 24.2979 149.08 23.5806V2.7406H149.062ZM154.304 20.9871C154.764 21.171 155.334 21.2998 155.867 21.2998C157.891 21.2998 158.369 19.4052 158.369 16.4438C158.369 13.9239 157.964 11.6799 155.904 11.6799C155.04 11.6799 154.322 12.2685 154.322 13.1698V20.9871H154.304Z"
            fill={color}
          />
          <path
            d="M165.837 19.4788C166.996 20.233 169.074 21.0975 170.472 21.0975C171.41 21.0975 171.962 20.7848 171.962 19.9203C171.962 18.9822 171.061 18.6695 169.938 18.3384C167.474 17.5659 165.009 16.6278 165.009 13.2618C165.009 9.84058 167.75 8.00122 171.244 8.00122C173.746 8.00122 175.99 8.9393 177.517 10.3372L175.144 13.3354C173.764 12.2685 172.495 11.7167 171.52 11.7167C170.656 11.7167 170.086 12.0846 170.086 12.8387C170.086 13.74 170.95 14.1447 172.238 14.5493C174.537 15.3035 177.13 16.2967 177.13 19.6076C177.13 23.1024 174.61 24.9417 170.766 24.9417C167.86 24.9417 165.432 23.8749 164.476 23.1944L165.837 19.4788Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_219_2387">
            <rect width="177.517" height="30" fill={color} />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export const ExploreArrow = (props) => {
  return (
    <>
      <svg
        {...props}
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_165_10266)">
          <path
            d="M5.5231 20.8275L3.67256 18.9769L14.7758 7.87363L12.9253 6.02309L14.7758 4.17254L16.6264 6.02309L18.4769 4.17254L20.3275 6.02309L18.4769 7.87363L20.3275 9.72418L18.4769 11.5747L16.6264 9.72418L5.5231 20.8275ZM18.4769 15.2758L20.3275 13.4253L18.4769 11.5747L16.6264 13.4253L18.4769 15.2758ZM18.4769 15.2758L16.6264 17.1264L18.4769 18.9769L20.3275 17.1264L18.4769 15.2758ZM9.22419 6.02309L11.0747 4.17254L12.9253 6.02309L11.0747 7.87363L9.22419 6.02309ZM9.22419 6.02309L7.37365 7.87363L5.5231 6.02309L7.37365 4.17254L9.22419 6.02309Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_165_10266">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
export const RightArrow = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.43 18.82C14.24 18.82 14.05 18.75 13.9 18.6C13.61 18.31 13.61 17.83 13.9 17.54L19.44 12L13.9 6.46C13.61 6.17 13.61 5.69 13.9 5.4C14.19 5.11 14.67 5.11 14.96 5.4L21.03 11.47C21.32 11.76 21.32 12.24 21.03 12.53L14.96 18.6C14.81 18.75 14.62 18.82 14.43 18.82Z"
          fill="#F84D2F"
        />
        <path
          d="M20.33 12.75H3.5C3.09 12.75 2.75 12.41 2.75 12C2.75 11.59 3.09 11.25 3.5 11.25H20.33C20.74 11.25 21.08 11.59 21.08 12C21.08 12.41 20.74 12.75 20.33 12.75Z"
          fill="#F84D2F"
        />
      </svg>
    </>
  );
}
export const LocationIcon = () => {
  return (
    <>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_143_47996)">
          <path
            d="M25.775 10.5625C24.4625 4.7875 19.425 2.1875 15 2.1875C15 2.1875 15 2.1875 14.9875 2.1875C10.575 2.1875 5.525 4.775 4.2125 10.55C2.75 17 6.7 22.4625 10.275 25.9C11.6 27.175 13.3 27.8125 15 27.8125C16.7 27.8125 18.4 27.175 19.7125 25.9C23.2875 22.4625 27.2375 17.0125 25.775 10.5625ZM15 16.825C12.825 16.825 11.0625 15.0625 11.0625 12.8875C11.0625 10.7125 12.825 8.95 15 8.95C17.175 8.95 18.9375 10.7125 18.9375 12.8875C18.9375 15.0625 17.175 16.825 15 16.825Z"
            fill="#F84D2F"
          />
        </g>
        <defs>
          <clipPath id="clip0_143_47996">
            <rect width="30" height="30" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
export const GlobalIcon = () => {
  return (
    <>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_143_47999)">
          <path
            d="M9.56245 26.1375C9.52495 26.1375 9.47495 26.1625 9.43745 26.1625C7.01245 24.9625 5.03745 22.975 3.82495 20.55C3.82495 20.5125 3.84995 20.4625 3.84995 20.425C5.37495 20.875 6.94995 21.2125 8.51245 21.475C8.78745 23.05 9.11245 24.6125 9.56245 26.1375Z"
            fill="#F84D2F"
          />
          <path
            d="M26.175 20.5625C24.9375 23.0501 22.875 25.0626 20.3625 26.2751C20.8375 24.6876 21.2375 23.0875 21.5 21.475C23.075 21.2125 24.625 20.875 26.15 20.425C26.1375 20.475 26.175 20.525 26.175 20.5625Z"
            fill="#F84D2F"
          />
          <path
            d="M26.275 9.6376C24.7 9.1626 23.1125 8.7751 21.5 8.5001C21.2375 6.8876 20.85 5.2876 20.3625 3.7251C22.95 4.9626 25.0375 7.0501 26.275 9.6376Z"
            fill="#F84D2F"
          />
          <path
            d="M9.56248 3.86245C9.11248 5.38745 8.78748 6.93745 8.52498 8.51245C6.91248 8.76245 5.31248 9.16245 3.72498 9.63745C4.93748 7.12495 6.94998 5.06245 9.43748 3.82495C9.47498 3.82495 9.52498 3.86245 9.56248 3.86245Z"
            fill="#F84D2F"
          />
          <path
            d="M19.3625 8.2375C16.4625 7.9125 13.5375 7.9125 10.6375 8.2375C10.95 6.525 11.35 4.8125 11.9125 3.1625C11.9375 3.0625 11.925 2.9875 11.9375 2.8875C12.925 2.65 13.9375 2.5 15 2.5C16.05 2.5 17.075 2.65 18.05 2.8875C18.0625 2.9875 18.0625 3.0625 18.0875 3.1625C18.65 4.825 19.05 6.525 19.3625 8.2375Z"
            fill="#F84D2F"
          />
          <path
            d="M8.2375 19.3624C6.5125 19.0499 4.8125 18.65 3.1625 18.0875C3.0625 18.0625 2.9875 18.0749 2.8875 18.0624C2.65 17.075 2.5 16.0625 2.5 15C2.5 13.95 2.65 12.925 2.8875 11.95C2.9875 11.9375 3.0625 11.9375 3.1625 11.9125C4.825 11.3625 6.5125 10.95 8.2375 10.6375C7.925 13.5375 7.925 16.4625 8.2375 19.3624Z"
            fill="#F84D2F"
          />
          <path
            d="M27.5 15C27.5 16.0625 27.35 17.075 27.1125 18.0624C27.0125 18.0749 26.9375 18.0625 26.8375 18.0875C25.175 18.6375 23.475 19.0499 21.7625 19.3624C22.0875 16.4625 22.0875 13.5375 21.7625 10.6375C23.475 10.95 25.1875 11.35 26.8375 11.9125C26.9375 11.9375 27.0125 11.95 27.1125 11.95C27.35 12.9375 27.5 13.95 27.5 15Z"
            fill="#F84D2F"
          />
          <path
            d="M19.3625 21.7625C19.05 23.4875 18.65 25.1874 18.0875 26.8374C18.0625 26.9374 18.0625 27.0125 18.05 27.1125C17.075 27.35 16.05 27.5 15 27.5C13.9375 27.5 12.925 27.35 11.9375 27.1125C11.925 27.0125 11.9375 26.9374 11.9125 26.8374C11.3625 25.1749 10.95 23.4875 10.6375 21.7625C12.0875 21.925 13.5375 22.0375 15 22.0375C16.4625 22.0375 17.925 21.925 19.3625 21.7625Z"
            fill="#F84D2F"
          />
          <path
            d="M19.7042 19.7042C16.5778 20.0986 13.4222 20.0986 10.2958 19.7042C9.90139 16.5778 9.90139 13.4222 10.2958 10.2958C13.4222 9.90139 16.5778 9.90139 19.7042 10.2958C20.0986 13.4222 20.0986 16.5778 19.7042 19.7042Z"
            fill="#F84D2F"
          />
        </g>
        <defs>
          <clipPath id="clip0_143_47999">
            <rect width="30" height="30" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
export const CallIcon = () => {
  return (
    <>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8125 18.6875L11.5 21C11.0125 21.4875 10.2375 21.4875 9.7375 21.0125C9.6 20.875 9.4625 20.75 9.325 20.6125C8.0375 19.3125 6.875 17.95 5.8375 16.525C4.8125 15.1 3.9875 13.675 3.3875 12.2625C2.8 10.8375 2.5 9.475 2.5 8.175C2.5 7.325 2.65 6.5125 2.95 5.7625C3.25 5 3.725 4.3 4.3875 3.675C5.1875 2.8875 6.0625 2.5 6.9875 2.5C7.3375 2.5 7.6875 2.575 8 2.725C8.325 2.875 8.6125 3.1 8.8375 3.425L11.7375 7.5125C11.9625 7.825 12.125 8.1125 12.2375 8.3875C12.35 8.65 12.4125 8.9125 12.4125 9.15C12.4125 9.45 12.325 9.75 12.15 10.0375C11.9875 10.325 11.75 10.625 11.45 10.925L10.5 11.9125C10.3625 12.05 10.3 12.2125 10.3 12.4125C10.3 12.5125 10.3125 12.6 10.3375 12.7C10.375 12.8 10.4125 12.875 10.4375 12.95C10.6625 13.3625 11.05 13.9 11.6 14.55C12.1625 15.2 12.7625 15.8625 13.4125 16.525C13.5375 16.65 13.675 16.775 13.8 16.9C14.3 17.3875 14.3125 18.1875 13.8125 18.6875Z"
          fill="#F84D2F"
        />
        <path
          d="M27.4625 22.9125C27.4625 23.2625 27.4 23.6251 27.275 23.975C27.2375 24.075 27.2 24.175 27.15 24.275C26.9375 24.725 26.6625 25.1501 26.3 25.5501C25.6875 26.225 25.0125 26.7126 24.25 27.0251C24.2375 27.0251 24.225 27.0375 24.2125 27.0375C23.475 27.3375 22.675 27.5 21.8125 27.5C20.5375 27.5 19.175 27.2001 17.7375 26.5876C16.3 25.975 14.8625 25.1501 13.4375 24.1126C12.95 23.7501 12.4625 23.3875 12 23L16.0875 18.9125C16.4375 19.175 16.75 19.375 17.0125 19.5125C17.075 19.5375 17.15 19.575 17.2375 19.6125C17.3375 19.65 17.4375 19.6625 17.55 19.6625C17.7625 19.6625 17.925 19.5875 18.0625 19.45L19.0125 18.5125C19.325 18.2 19.625 17.9625 19.9125 17.8125C20.2 17.6375 20.4875 17.55 20.8 17.55C21.0375 17.55 21.2875 17.6 21.5625 17.7125C21.8375 17.825 22.125 17.9875 22.4375 18.2L26.575 21.1375C26.9 21.3625 27.125 21.625 27.2625 21.9375C27.3875 22.25 27.4625 22.5625 27.4625 22.9125Z"
          fill="#F84D2F"
        />
      </svg>
    </>
  );
}
export const LinkedinIcon = () => {
  return (
    <>
      <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.80762 4.53027H0.883789V13.8877H3.80762V4.53027Z"
          fill="#101320"
        />
        <path
          d="M2.28418 0.112305C1.20605 0.112305 0.49707 0.809571 0.49707 1.73535C0.49707 2.64356 1.18262 3.3584 2.24316 3.3584H2.2666C3.36816 3.3584 4.05371 2.6377 4.04785 1.73535C4.02441 0.809571 3.3623 0.112305 2.28418 0.112305Z"
          fill="#101320"
        />
        <path
          d="M11.7939 4.44238C10.1182 4.44238 9.06934 5.35645 8.87598 6.00098V4.53027H5.58887C5.62988 5.30957 5.58887 13.8877 5.58887 13.8877H8.87598V8.83105C8.87598 8.54394 8.86426 8.2627 8.94629 8.06348C9.16895 7.50098 9.65527 6.91504 10.5283 6.91504C11.6709 6.91504 12.1865 7.78223 12.1865 9.04785V13.8877H15.5029V8.68457C15.5029 5.79004 13.874 4.44238 11.7939 4.44238Z"
          fill="#101320"
        />
      </svg>
    </>
  );
}
export const FacebookIcon = () => {
  return (
    <>
      <svg
        width="8"
        height="16"
        viewBox="0 0 8 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.9834 2.89063C6.58691 2.89063 7.23145 3.07812 7.23145 3.07812L7.61816 0.781251C7.61816 0.781251 6.79785 0.5 4.84082 0.5C3.63965 0.5 2.94238 0.957032 2.43262 1.63086C1.95215 2.26953 1.93457 3.29492 1.93457 3.95703V5.46289H0.381836V7.70703H1.93457V15.5H4.84082V7.70703H7.14356L7.31348 5.46289H4.84082V3.71094C4.84082 3.10742 5.37988 2.89063 5.9834 2.89063Z"
          fill="#101320"
        />
      </svg>
    </>
  );
}
export const TwitterIcon = () => {
  return (
    <>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.0258 0.672607H13.1724L8.48255 6.03305L14 13.3272H9.67974L6.29619 8.90322L2.42453 13.3272H0.276483L5.29277 7.59369L0 0.672607H4.42964L7.48818 4.71619L11.0258 0.672607ZM10.2723 12.0422H11.4619L3.78336 1.89011H2.50699L10.2723 12.0422Z"
          fill="#101320"
        />
      </svg>
    </>
  );
}
export const LineIcon = (props) => {
  return (
    <>
      <svg
        {...props}
        width="302"
        height="12"
        viewBox="0 0 302 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.43066 5C0.878379 5 0.430664 5.44772 0.430664 6C0.430664 6.55228 0.878379 7 1.43066 7V5ZM291.431 7L301.431 11.7735V0.226497L291.431 5V7ZM1.43066 7H292.431V5H1.43066V7Z"
          fill="url(#paint0_linear_45_3942)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_45_3942"
            x1="1.43066"
            y1="6.5"
            x2="301.431"
            y2="6.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EC1C24" />
            <stop offset="0.525" stopColor="#FCB81D" />
            <stop offset="1" stopColor="#0BA94B" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
}
export const TickMarkGreenIcon = (props) => {
  return (
    <>
      <svg
        {...props}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
          fill="#23B339"
        />
        <path
          d="M10.5775 15.5801C10.3775 15.5801 10.1875 15.5001 10.0475 15.3601L7.2175 12.5301C6.9275 12.2401 6.9275 11.7601 7.2175 11.4701C7.5075 11.1801 7.9875 11.1801 8.2775 11.4701L10.5775 13.7701L15.7175 8.6301C16.0075 8.3401 16.4875 8.3401 16.7775 8.6301C17.0675 8.9201 17.0675 9.4001 16.7775 9.6901L11.1075 15.3601C10.9675 15.5001 10.7775 15.5801 10.5775 15.5801Z"
          fill="#23B339"
        />
      </svg>
    </>
  );
};