import React from 'react'

const CommonSection = ({children}) => {
  return (
    <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] py-[100px] max_md:py-[50px] max_sm:py-[30px] max_md:px-10 max_sm:px-5">
      {children}
    </div>
  );
}

export default CommonSection