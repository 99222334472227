import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const CookiePolicyContent = () => {
  const { t } = useTranslation();

  const [cookieData] = useState([
    {
      title: t('Ques1'),
      text: [t('Ques1_1'), t('Ques1_2')],
    },
    {
      title: t('Ques2'),
      text: [t('Ques2_1')],
    },
    {
      title: t('Ques3'),
      points: [
        t('Ques3_1'),
        t('Ques3_2'),
        t('Ques3_3'),
        t('Ques3_4'),
        t('Ques3_5'),
      ],
    },
    {
      title: t('Ques4'),
      text: [t('Ques4_1'), t('Ques4_2'), t('Ques4_3')],
    },
    {
      title: t('Ques5'),
      text: [t('Ques5_1')],
    },
    {
      title: t('Ques6'),
      text: [t('Ques6_1')],
    },
    {
      title: t('Ques7'),
      text: [t('Ques7_1'), t('Ques7_2')],
    },
    {
      title: t('Ques8'),
      text: [t('Ques8_1')],
    },
    {
      title: t('Ques9'),
      text: [t('Ques9_1')],
    },
    {
      title: t('Ques10'),
      text: [t('Ques10_1')],
    },
  ]);

  const handleOnClick = (event) => {
    let element = event.target;
    while (element != null && !element.classList.contains('underlineSpan')) {
      element = element.parentElement;
    }

    if (element && element.dataset.url) {
      if (element.dataset.url.includes('http://')) {
        window.open(element.dataset.url, '_blank');
      }
    }
  };
  return (
    <div className="mt-[60px] max_md:mt-[50px] max_sm:mt-[30px] flex flex-col gap-[60px] max_md:gap-[50px] max_sm:gap-[30px]">
      <p className="Text18Normal text-TernaryT">{t('CookiePolicyText')}</p>
      <div className="flex flex-col gap-[30px] max_md:gap-6 max_sm:gap-4">
        {cookieData?.map((list, index) => (
          <React.Fragment key={index}>
            <p className="Text20Semi">
              {list.title}
            </p>
            {list.text && (
              <>
                <div className="flex flex-col gap-6 max_sm:gap-4">
                  {list.text.map((innerText, index1) => (
                    <p
                      key={index1}
                      className="Text18Normal text-TernaryT"
                      dangerouslySetInnerHTML={{ __html: innerText }}
                      onClick={handleOnClick}
                    ></p>
                  ))}
                </div>
              </>
            )}
            {list.points && (
              <>
                <div className="flex flex-col gap-6 max_sm:gap-4">
                  {list.points.map((list2, index2) => (
                    <div className="flex gap-2" key={index2}>
                      <p className="w-[6px] h-[6px] rounded-full bg-TernaryT mt-[10px] max_sm:mt-[7px]"></p>
                      <p
                        className="Text18Normal text-TernaryT w-[calc(100%-14px)]"
                        dangerouslySetInnerHTML={{
                          __html: list2,
                        }}
                      ></p>
                    </div>
                  ))}
                </div>
              </>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default CookiePolicyContent;
