import React from 'react';
import { useTranslation } from 'react-i18next';

const OurStory = ({ sectionRefs }) => {
  const { t } = useTranslation();
  return (
    <div
      className="max-w-[1240px] max_xl:max-w-[980px] m-auto pt-[100px] max_md:pt-[50px] max_sm:pt-[30px] pb-[50px] max_sm:pb-[30px] max_md:px-10 max_sm:px-5"
      id="OurStoryId"
      ref={sectionRefs}
    >
      <p className="Text24 text-PrimaryT">{t('OurStory')}</p>

      <h2 className="Text50Bold text-PrimaryT max-w-[740px] mt-5">
        {t('OurStoryTitle')}
        <span className="text-ButtonPrimary"> {t('difference')}</span>
      </h2>

      <div className="flex mt-[60px] max_md:mt-10 max_sm:mt-[30px] justify-between gap-[50px] max_md:flex-col max_md:gap-5">
        <div className="flex flex-col gap-[30px] text-TernaryT Text18Normal max-w-[550px] max_xl:max-w-[450px] max_md:max-w-full max_md:gap-5">
          <p>{t('StoryText1')}</p>
          <p>{t('StoryText2')}</p>
        </div>
        <div className="flex flex-col gap-[30px] text-TernaryT Text18Normal max-w-[550px] max_xl:max-w-[450px] max_md:max-w-full max_md:gap-5">
          <p>{t('StoryText3')}</p>
          <p dangerouslySetInnerHTML={{ __html: t('StoryText4') }}></p>
        </div>
      </div>
    </div>
  );
};

export default OurStory;
