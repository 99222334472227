import React from 'react';

import Menu from '../assets/images/menu.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PLLogo } from '../assets/icons/icon';
import { ButtonFilled } from './Buttons';
import { useWindowSize } from './Hooks/useWindowSize';

const Navbar = ({ setIsMobileMenuOpen, navbarChangeColor }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  // const [MenuList, setMenuList] = useState([]);
  const { width: windowWidth } = useWindowSize();
  const isMobileview = windowWidth < 500;

  // useEffect(() => {
  //   setMenuList(getTranslatedList(t));
  // }, [t]);

  const handleClickScroll = (id) => {
    if (location.pathname !== '/') {
      navigate('/');
    }
    setTimeout(() => {
      const element = document.getElementById(id);

      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - 80;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    },500);
  };

  return (
    <div className="relative max-w-[1920px] m-auto overflow-hidden">
      <div className="relative z-[20] w-[100%] m-auto flex h-[66px] items-center max_md:px-10 max_sm:px-5 max-w-[1240px] max_xl:max-w-[980px]">
        <div className="FLEX_CENTER w-full justify-between ">
          <div className="anime-fade-in flex items-center justify-between cursor-pointer max_md:w-[100%]">
            <div
              className="z-40 w-[140px] max_sm:w-[90px]"
              onClick={() => {
                if (location.pathname === '/') {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                } else {
                  navigate('/');
                  setTimeout(() => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'smooth',
                    });
                  }, 100);
                }
              }}
            >
              {/* <img draggable="false" src={AvnovoLogo} alt="Logo" /> */}
              <PLLogo
                color={
                  navbarChangeColor
                    ? '#000000'
                    : location.pathname === '/'
                    ? '#FFFFFF'
                    : '#333333'
                }
                width={isMobileview ? 120 : 178}
              />
            </div>
            <div className="hidden max_md:block">
              <div className="flex items-center gap-7 max_sm:gap-3">
                <div
                  className="cursor-pointer z-40"
                  onClick={() => {
                    setIsMobileMenuOpen(true);
                  }}
                >
                  <img src={Menu} alt="Menu" />
                </div>
              </div>
            </div>
          </div>

          <div className="relative FLEX_CENTER flex gap-x-[20px] max_md:hidden">
            {/* {MenuList.map((menu, index) => {
              return (
                <div key={index}>
                  <NavLink
                    className="z-[90] flex h-[50px] w-full items-center justify-end no-underline"
                    to={menu.link}
                    onClick={(e) => handleClick(menu.link, e)}
                  >
                    {({ isActive }) => (
                      <>
                        <div
                          className={`cursor-pointer font-OutfitFont text-textSmall font-normal ${
                            navbarChangeColor
                              ? 'text-SecondaryT hover:text-PrimaryT'
                              : location.pathname === '/'
                              ? 'text-white'
                              : 'text-SecondaryT hover:text-PrimaryT'
                          }`}
                          // ref={outSideRef}
                        >
                          {menu.name}
                        </div>
                      </>
                    )}
                  </NavLink>
                </div>
              );
            })} */}
            {/* Popup menu  */}

            <div className="z-[60]">
              <ButtonFilled
                title={t('ContactUs')}
                fontSize="16px"
                rounded="12px"
                onClick={() => handleClickScroll('ContactId')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
