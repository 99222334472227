import React, { useState } from 'react';
import CommonContainer from '../Common/CommonContainer';
import { useTranslation } from 'react-i18next';
import FacebookImg from '../../assets/images/Homepage/facebook.svg';
import TwitterImg from '../../assets/images/Homepage/twitter.svg';
import LinkedinImg from '../../assets/images/Homepage/linkedin.svg';
import InstagramImg from '../../assets/images/Homepage/Instagram.svg';
import YoutubeImg from '../../assets/images/Homepage/youtube.svg';
import DCDialImg from '../../assets/images/Homepage/DcDialImg.svg';
import AVImg from '../../assets/images/Homepage/AvnovoImg.svg';
import EPImg from '../../assets/images/Homepage/EpifonyImg.svg';
import { RightArrow } from '../../assets/icons/icon';
import {
  Img1Left,
  Img1Right,
  Img2Left,
  Img2Right,
  Img3Left,
  Img3Right,
} from '../../assets/icons/HomeIcons';
import { useWindowSize } from '../Hooks/useWindowSize';

const OurProducts = ({ sectionRefs }) => {
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 600;
  const [products, setProducts] = useState([
    {
      title: t('DCDial'),
      id: 'DCDialId',
      text: t('DcDialT'),
      startedText: t('StartedDcDial'),
      link: 'https://www.dcdial.com/',
      image: DCDialImg,
      socialIcon: [
        {
          icon: FacebookImg,
          link: 'https://www.facebook.com/dcdialapp/?ref=py_c',
        },
        {
          icon: TwitterImg,
          link: 'https://twitter.com/dcdialapp?lang=en',
        },
        {
          icon: LinkedinImg,
          link: 'https://www.linkedin.com/company/dcdialapp/',
        },
        {
          icon: InstagramImg,
          link: 'https://www.instagram.com/dcdialapp/?igshid=1gxcnae30t2xg',
        },
        {
          icon: YoutubeImg,
          link: 'https://www.youtube.com/channel/UCc6nP07WWreh--Ees7merAw',
        },
      ],
    },
    {
      title: t('Avnovo'),
      id: 'AvnovoId',
      text: t('AvnovoT'),
      startedText: t('StartedAv'),
      link: 'https://avnovo.com/',
      image: AVImg,
      socialIcon: [
        {
          icon: FacebookImg,
          link: 'https://www.facebook.com/avnovo',
        },
        {
          icon: TwitterImg,
          link: 'https://twitter.com/avnovoapp',
        },
        {
          icon: LinkedinImg,
          link: 'https://www.linkedin.com/company/avnovo/',
        },
        {
          icon: InstagramImg,
          link: 'https://www.instagram.com/avnovoapp/',
        },
        {
          icon: YoutubeImg,
          link: 'https://www.youtube.com/channel/UC06V-62l1SFIComAB22Mnkw/featured',
        },
      ],
    },
    {
      title: t('Epifony'),
      id: 'EpifonyId',
      text: t('EpifonyT'),
      startedText: t('StartedEP'),
      link: 'https://epifony.com/',
      image: EPImg,
      socialIcon: [
        {
          icon: FacebookImg,
          link: 'https://www.facebook.com/epifony',
        },
        {
          icon: TwitterImg,
          link: 'https://x.com/epifonyapp?s=21&t=05XkzAiBnckpo733xUsSAw',
        },
        {
          icon: LinkedinImg,
          link: 'https://www.linkedin.com/company/epifony/',
        },
        {
          icon: InstagramImg,
          link: 'https://www.instagram.com/epifonyapp?igsh=MXZmcTQxemkzYTBvNw==',
        },
      ],
    },
  ]);
  return (
    <div className="max-w-[1920px] m-auto overflow-hidden">
      <CommonContainer>
        <div className="pt-[50px]" id="ProductId">
          <p className="Text24 text-PrimaryT">{t('OurProducts')}</p>
          <h2 className="Text50Bold text-PrimaryT max-w-[740px] mt-5">
            {t('Driving')}
            <span className="text-ButtonPrimary"> {t('InnovationL')}</span>
          </h2>
          {products.map((product, index) => (
            <div
              className={`py-[100px] max_md:py-[50px] max_sm:py-[30px] flex justify-between items-center gap-[60px] max_md:gap-[100px] max_sm:gap-[50px] max_md:flex-col ${
                index % 2 === 0 ? '' : 'flex-row-reverse'
              }`}
              id={product.id}
              ref={
                index === 0
                  ? sectionRefs.current.Dcdial
                  : index === 1
                  ? sectionRefs.current.avnovo
                  : sectionRefs.current.epifony
              }
              key={index}
            >
              <div className="flex flex-col gap-[30px] max_md:gap-5 max_sm:gap-4 max-w-[500px] max_xl:max-w-[430px] max_md:max-w-[100%]">
                <p className="Text40">{product.title}</p>
                <p className="Text18Normal text-TernaryT">{product.text}</p>
                <a href={product.link} target='_blank' className='w-max'>
                  <div className="flex gap-1 items-center cursor-pointer w-max">
                    <p className="Text18Normal font-medium text-ButtonPrimary">
                      {product.startedText}
                    </p>
                    <div>
                      <RightArrow />
                    </div>
                  </div>
                </a>
                <div className="flex gap-3">
                  {product.socialIcon.map((social, index1) => (
                    <a href={social.link} target="_blank" key={index1}>
                      <div className="cursor-pointer">
                        <img src={social.icon} alt="facebook" />
                      </div>
                    </a>
                  ))}
                </div>
              </div>
              <div className="relative ">
                <img src={product.image} alt={product.title} className="z-30" />
                {index === 0 && (
                  <>
                    <div className="absolute right-[-70px] max_sm:right-[-46px] top-[-40px] -z-10">
                      <Img1Right
                        style={{
                          width: isMobileView ? '65' : '106',
                          height: isMobileView ? '115' : '156',
                        }}
                      />
                    </div>
                    <div className="absolute left-[-50px] max_sm:left-[-36px] bottom-0 -z-10">
                      <Img1Left
                        style={{
                          width: isMobileView ? '66' : '88',
                          height: isMobileView ? '66' : '88',
                        }}
                      />
                    </div>
                  </>
                )}
                {index === 1 && (
                  <>
                    <div className="absolute left-[-22px] top-[128px] max_sm:top-[70px] -z-10">
                      <Img2Left
                        style={{
                          width: isMobileView ? '65' : '106',
                          height: isMobileView ? '115' : '156',
                        }}
                      />
                    </div>
                    <div className="absolute right-[-50px] max_sm:right-[-26px] bottom-[32px] -z-10">
                      <Img2Right
                        style={{
                          width: isMobileView ? '50' : '106',
                          height: isMobileView ? '90' : '156',
                        }}
                      />
                    </div>
                  </>
                )}
                {index === 2 && (
                  <>
                    <div className="absolute right-[-33px] max_sm:right-[-10px] max_sm:top-0 top-[-9px] -z-10">
                      <Img3Right
                        style={{
                          width: isMobileView ? '95' : '186',
                          height: isMobileView ? '95' : '186',
                        }}
                      />
                    </div>
                    <div className="absolute left-[-50px] max_sm:left-[-30px] max_sm:bottom-[70px] bottom-[82px] -z-10">
                      <Img3Left
                        style={{
                          width: isMobileView ? '50' : '106',
                          height: isMobileView ? '70' : '156',
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </CommonContainer>
    </div>
  );
};

export default OurProducts;
