import React from 'react'
import Layout from './Layout';
import { Outlet } from 'react-router-dom';

const CommonLayout = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default CommonLayout