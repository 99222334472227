import React from 'react';
import HomePage from './Pages/HomePage';
import { Route, Routes } from 'react-router-dom';
import CommonLayout from './Components/CommonLayout';
import i18n from './i18n';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import PageNotFound from './Pages/PageNotFound';
import CookiePolicy from './Pages/CookiePolicy';
function App() {
  // const handleSuspiciousActivity = () => {
  //   // setShowCaptcha(true);
  //   console.log("1111111");
  // };
  return (
    // <div lang="en" dir="ltr" id="main-app">
    <>
      {/* <ActivityMonitor onSuspiciousActivity={handleSuspiciousActivity} /> */}
      <Routes>
        <Route element={<CommonLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      
    </>
    // </div>
  );
}

export default App;
