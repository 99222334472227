import React from 'react'

const CommonContainer = ({children}) => {
  return (
    <div className="m-auto max-w-[1240px] max_xl:max-w-[980px] max_md:px-10 max_sm:px-5">
      {children}
    </div>
  );
}

export default CommonContainer