import React, { useEffect, useState } from "react";
import CommonSection from "../Common/CommonSection";
import { useTranslation } from "react-i18next";
import {
  CallIcon,
  FacebookIcon,
  GlobalIcon,
  LinkedinIcon,
  LocationIcon,
  TwitterIcon,
} from "../../assets/icons/icon";

import ContactForm from "./ContactForm";
import axios from "axios";

const ContactUs = () => {
  const { t } = useTranslation();

  const [userCountry, setUserCountry] = useState({
    ip: "",
    countryName: "",
    country_code: "",
  });
  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setUserCountry({
          ...userCountry,
          ip: data.ip,
          countryName: data.country_name,
          country_code: data.country_code,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  return (
    <CommonSection>
      <div
        className="flex justify-between max_md:flex-col max_md:gap-[60px] max_sm:gap-[30px]"
        id="ContactId"
      >
        <div className="max-w-[500px] max_xl:max-w-[440px] max_md:max-w-full">
          <h2 className="Text50Bold text-PrimaryT max-w-[740px]">
            {t("Contact")}
            <span className="text-ButtonPrimary"> {t("Us")}</span>
          </h2>
          <p className="Text16Normal mt-5">{t("ContactText")}</p>
          <div className="flex flex-col gap-[30px] mt-[60px] max_md:mt-10 max_sm:mt-[30px] max_sm:gap-5">
            <div className="flex gap-4 items-center">
              <LocationIcon />
              <p className="Text16Normal">{t("Address")}</p>
            </div>
            <div className="flex gap-4 items-center">
              <GlobalIcon />
              <p className="Text16Normal">support@palmlabs.com</p>
            </div>
            <div className="flex gap-4 items-center">
              <CallIcon />
              <p className="Text16Normal">+1 (404) 410-2722</p>
            </div>
          </div>
          <div className="mt-[60px] max_md:mt-10 max_sm:mt-[30px] flex gap-3">
            <a
              href="https://www.linkedin.com/company/palm-labs"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="w-10 h-10 cursor-pointer FLEX_CENTER rounded-full bg-BgSecondary">
                <LinkedinIcon />
              </div>
            </a>
            <a
              href="https://www.facebook.com/PalmLabs/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="w-10 h-10 cursor-pointer FLEX_CENTER rounded-full bg-BgSecondary">
                <FacebookIcon />
              </div>
            </a>
            <a
              href="https://x.com/palm_labs"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="w-10 h-10 cursor-pointer FLEX_CENTER rounded-full bg-BgSecondary">
                <TwitterIcon />
              </div>
            </a>
          </div>
        </div>
        <div className="w-[1px] h-[690px] bg-BgSecondary max_md:hidden"></div>

        <ContactForm userCountry={userCountry} />
      </div>
    </CommonSection>
  );
};

export default ContactUs;
