export const InputField = ({
  type,
  placeholder,
  handleOnChange,
  name,
  error,
  value,
  label,
  isRequired,
}) => {
  return (
    <div>
      <p className="text-textSmall max_sm:text-textExtraSmall font-OutfitFont font-normal text-PrimaryT mb-2">
        {label}
        {isRequired && <span className="text-[#FF0000]">*</span>}:
      </p>
      <input
        style={{ border: error ? '1px solid #FF0000' : '' }}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={(e) => handleOnChange(e, name)}
        className={`w-full outline-none border-[1px] border-StrokeSecondary py-3 px-5 rounded-[12px] font-OutfitFont text-textSmall max_sm:text-textExtraSmall text-PrimaryT font-normal`}
      />
    </div>
  );
};
export const InputTextArea = ({
  type,
  placeholder,
  handleOnChange,
  name,
  error,
  value,
  label,
  isRequired,
}) => {
  return (
    <div>
      <p className="text-textSmall max_sm:text-textExtraSmall font-OutfitFont font-normal text-PrimaryT mb-2">
        {label}
        {isRequired && <span className="text-[#FF0000]">*</span>}:
      </p>
      <textarea
        style={{ border: error ? '1px solid #FF0000' : '' }}
        type={type}
        value={value}
        rows={3}
        placeholder={placeholder}
        onChange={(e) => handleOnChange(e, name)}
        className={`w-full outline-none border-[1px] border-StrokeSecondary py-3 px-5 rounded-[12px] font-OutfitFont text-textSmall max_sm:text-textExtraSmall text-PrimaryT font-normal resize-none`}
      />
    </div>
  );
};
