import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CommonSection from '../Components/Common/CommonSection';
import PrivacyPolicyContent from '../Components/Privacy/PrivacyPolicyContent';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <CommonSection>
        <h2 className="Text50Bold text-PrimaryT max-w-[740px]">
          {t('Privacy')}
          <span className="text-ButtonPrimary"> {t('Policy')}</span>
        </h2>
        <p className="Text24 text-PrimaryT mt-5 max_sm:mt-3">
          Last Modified: June 25, 2024
        </p>
        <PrivacyPolicyContent />
      </CommonSection>
    </div>
  );
};

export default PrivacyPolicy;
